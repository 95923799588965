var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.mergeLoading,
          expression: "mergeLoading",
        },
      ],
      attrs: {
        title: "合并到另一项目",
        width: "600px",
        "show-close": false,
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("p", { staticClass: "merge-tip" }, [
        _vm._v(
          "\n        合并后，该项目所有信息将合并为你所选中的项目信息。且所属的所有职位将归属被选中的项目。\n    "
        ),
      ]),
      _c(
        "el-form",
        { ref: "mergeForm", attrs: { model: _vm.mergeForm, rules: _vm.rules } },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "mergeId", required: "" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    filterable: "",
                    clearable: "",
                    placeholder: "搜索项目名称",
                  },
                  model: {
                    value: _vm.mergeForm.mergeId,
                    callback: function ($$v) {
                      _vm.$set(_vm.mergeForm, "mergeId", $$v)
                    },
                    expression: "mergeForm.mergeId",
                  },
                },
                _vm._l(_vm.mergeProjectArr, function (item) {
                  return _c("el-option", {
                    key: item.projectId,
                    attrs: { label: item.projectName, value: item.projectId },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.commitLoadig },
              on: { click: _vm.handleProjectMerge },
            },
            [_vm._v("确定")]
          ),
          _c(
            "el-button",
            {
              attrs: { loading: _vm.commitLoadig },
              on: { click: _vm.handleCloseDialog },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }