<template>
    <div class="program-detail">
        <!-- 顶部 -->
        <div class="top-bar-wrap">
            <program-detail-top
                :topData="baseInfo"
                :isBindJob="isBindJob"
                @add-job="addJob"
                @update-delivery-infomation="updateDeliveryInfomation"
                @merge-project="mergeProject"
                @edit-program="editProgram"
                @delete-program="deleteProgram"
            />
        </div>

        <!-- 主体 -->
        <div class="main">
            <div class="content">
                <!-- 我的项目交付目标 -->
                <my-program-goal
                    :isBindJob="isBindJob"
                    @eidt-goal="editGoal"
                    @add-goal="addGoal"
                    :goalData="projectDeliveryGoalItems"
                />
                <!-- 项目描述和相关职位 -->
                <program-list
                    ref="programList"
                    :programListData="programListData"
                    @get-project-job-finish="getProjectJobFinish"
                    @program-list-deal-finish="programListDealFinish"
                />
            </div>
            <!-- 创建人详情和最新动态 -->
            <div class="side-bar">
                <program-detail-right-side
                    ref="programRightSide"
                    :projectName="baseInfo.name"
                    :baseData="baseInfo"
                    :userInfo="userInfo"
                    :shareData="shareData"
                    :isOwner="baseInfo.isOwner"
                />
            </div>
        </div>

        <!-- 编辑项目弹出窗口 -->
        <edit-program-dialog
            v-if="isEditProgram"
            :projectParams="projectParams"
            @edit-program-finish="editProgramFinish"
        />

        <!-- 更新交付信息 -->
        <update-delivery-infomation
            v-if="isUpdateDeliveryInfomation"
            :projectId="projectId"
            :baseData="baseInfo"
            @update-delivery-infomation-finish="updateDeliveryInfomationFinish"
        />

        <!-- 添加项目交互目标弹出窗口 -->
        <add-program-goal
            v-if="isAddGoal"
            :goalParams="goalParams"
            :baseData="baseInfo"
            @add-goal-finish="addGoalFinish"
            :programGoalTitle="programGoalTitle"
        />

        <!-- 添加职位交互弹出窗口 -->
        <add-job-dialog
            v-if="isAddJob"
            @add-job-finish="addJobFinish"
            @bind-newJob="isBindNewJob"
        />

        <!-- 合并到另一项目弹出窗口 -->
        <merge-project-dialog
            ref="mergeProjectDialog"
            :mergeProjectArr="mergeProjectArr"
            :projectId="projectId"
        />
    </div>
</template>

<script>
import moment from "moment";
import ProgramDetailTop from "./layout/program-detail-top.vue";
import MyProgramGoal from "./layout/my-program-goal.vue";
import ProgramList from "./layout/program-list.vue";
import ProgramDetailRightSide from "./layout/program-detail-right-side.vue";

import EditProgramDialog from "./component/edit-program.vue";
import UpdateDeliveryInfomation from "./component/update-delivery-infomation.vue";
import AddProgramGoal from "./component/add-program-goal.vue";
import AddJobDialog from "./component/add-job-dialog.vue";
import MergeProjectDialog from "./component/merge-project-dialog.vue";

import projectDetailService from "#/js/service/projectDetailService.js";

export default {
    components: {
        ProgramDetailTop,
        MyProgramGoal,
        ProgramList,
        ProgramDetailRightSide,

        EditProgramDialog,
        UpdateDeliveryInfomation,
        AddProgramGoal,
        AddJobDialog,
        MergeProjectDialog,
    },
    data() {
        return {
            isBindJob: false, // 判断是否项目绑定了职位
            isEditProgram: false,
            projectParams: {}, // 编辑项目参数

            programGoalTitle: false,

            isUpdateDeliveryInfomation: false,
            isAddGoal: false,
            goalParams: {}, // 添加和编辑目标的参数
            isAddJob: false,
            programDetail: {}, // 项目详情页面数据
            programListData: {}, // 项目列表数据

            // 项目交付目标
            projectDeliveryGoalItems: [],
            userInfo: {},
            baseInfo: {},
            isFirst: true,

            // 可合并项目列表
            mergeProjectArr: [
                // {
                //     projectId: "ad350372-b91c-462c-9f16-019060d60662",
                //     projectName: "58研发技术",
                // },
                // {
                //     projectId: "ad3503e7-5478-4a47-8f9e-2034fa85800c",
                //     projectName: "ggg1",
                // },
            ],
        };
    },
    computed: {
        projectId() {
            return this.$route.params.projectId;
        },
        shareData() {
            let baseInfo = this.baseInfo || {};
            let userInfo = this.userInfo || {};
            return {
                // isPublic: baseInfo.isPublic,
                // jobId: baseInfo.id,
                // jobName: baseInfo.name,
                firmShortName: userInfo.firmName,
                location: baseInfo.locationIds,
                // yearOfExperience: baseInfo.yearOfExperience,
                // degree: baseInfo.degree,
                // salary: baseInfo.salary == '0K-0K' ? '面议' : baseInfo.salary,
                // commissionValue: baseInfo.commissionValue && baseInfo.commissionValue.indexOf('0K-0K') > -1 ? '按比例' : baseInfo.commissionValue,
                creatorId: userInfo.userId,
                creatorName: `${userInfo.realName}@${userInfo.nikeName}`,
                creatorAvatar: userInfo.avatarUrl,
                // isInnerJob: !baseInfo.isPublic
            };
        },
    },
    mounted() {},
    created() {
        this.init();
    },
    methods: {
        getProjectJobFinish(jobList) {
            console.log(jobList);
            if(jobList.length > 0) {
                this.isBindJob = jobList.some(item => {
                    return item.isBinding;
                })
            } else {
                this.isBindJob = false;
            }
        },
        // 初始化获取页面详情数据
        init() {
            // 通过项目id获取项目详情
            const param = {
                id: this.projectId,
            };
            projectDetailService
                .getProjectDetailInfo(param)
                .then((res) => {
                    this.userInfo = {
                        created: moment(res.created).format("YYYY-MM-DD"),
                        ...res.projectPublisherInfo,
                    };
                    // 切割项目名称的 $#$ 符号
                    // res.name = res.name.replace("$#$", "-");
                    this.baseInfo = res;
                    this.programListData = {
                        projectId: this.projectId,
                        description: res.description,
                    };
                })
                .then(() => {
                    if (
                        ((this.$route.query.hasOwnProperty("nocustomer") &&
                            this.noCustomer()) ||
                            (this.$route.query.hasOwnProperty("nodepartment") &&
                                this.noDepartment())) &&
                        this.isFirst
                    ) {
                        this.isFirst = false;
                        this.editProgram();
                    }
                });
            // 获取项目的交付目标
            this.getProjectGoal();
            this.$refs.programRightSide &&
                this.$refs.programRightSide.requestLatestTrends(true);
            this.$refs.programList &&
                this.$refs.programList.getProjectJobPage(true);
        },

        // 添加工作
        addJob() {
            this.isAddJob = true;
        },
        addJobFinish(isEdit) {
            this.isAddJob = false;
            if (isEdit) {
                this.init();
            }
        },

        // 更新交付信息
        updateDeliveryInfomation() {
            this.isUpdateDeliveryInfomation = true;
        },

        // 添加目标
        addGoal() {
            this.goalParams = {
                type: "add",
                businessType: "project",
                porjectId: this.projectId,
                projectName: this.baseInfo.name,
            };
            this.isAddGoal = true;
        },

        updateDeliveryInfomationFinish(type) {
            // console.log(type);
            this.isUpdateDeliveryInfomation = false;
            if(type != 'cancel') {
                this.init();
            }
        },
        addGoalFinish(isEdit) {
            this.isAddGoal = false;
            this.programGoalTitle = false;
            if (isEdit) {
                // this.getProjectGoal();
                this.init();
                this.$refs.programRightSide &&
                    this.$refs.programRightSide.requestLatestTrends(true);
            }
        },
        // 编辑目标
        editGoal(index) {
            this.goalParams = Object.assign(
                {
                    type: "edit",
                    businessType: "project",
                    porjectId: this.projectId,
                    projectName: this.baseInfo.name,
                },
                this.projectDeliveryGoalItems[index]
            );
            this.isAddGoal = true;
        },

        // 编辑项目
        editProgram() {
            this.projectParams = Object.assign(
                {
                    projectId: this.projectId,
                },
                this.baseInfo
            );
            // console.log(this.projectParams);
            this.isEditProgram = true;
        },
        editProgramFinish(isEdit) {
            this.isEditProgram = false;
            // 刷新页面获取数据
            if (isEdit) {
                this.init();
            }
        },

        // 职位列表删除操作的回调
        programListDealFinish() {
            this.init();
        },

        // 删除项目
        deleteProgram() {
            console.log(this.baseInfo.name);
            let deleteProjectName = this.baseInfo.name.replace("$#$", "-")
            this.$confirm(
                // `若删除该项目，则该项目下绑定的职位均会取消绑定，是否确定删除“${this.baseInfo.name}”项目？`,
                `若删除该项目，则该项目下绑定的职位均会取消绑定，是否确定删除“${deleteProjectName}”项目？`,
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            )
                .then(() => {
                    const params = {
                        projectId: this.projectId,
                    };
                    const loading = this.$loading({
                        lock: true,
                        text: "正在删除项目~",
                        background: "rgba(255, 255, 255, 0.7)",
                    });
                    projectDetailService.deleteProject(params).then((res) => {
                        loading.close();
                        shortTips("删除项目成功！");
                        // location.href = `/Headhunting/MyCompany.html#/project`;
                        location.href = `/#/project`;
                    });
                })
                .catch(() => {
                    shortTips("已取消删除操作");
                });
        },

        noCustomer() {
            if (this.$route.query.hasOwnProperty("nocustomer")) {
                return (
                    this.baseInfo.customerId ==
                    "00000000-0000-0000-0000-000000000000"
                );
            } else {
                return (
                    this.baseInfo.customerId ==
                    "00000000-0000-0000-0000-000000000000"
                );
            }
        },
        noDepartment() {
            if (this.$route.query.hasOwnProperty("nodepartment")) {
                return this.baseInfo.customerDeparmentName == null;
            } else {
                return this.baseInfo.customerDeparmentName == null;
            }
        },

        // 获取项目的交付目标
        getProjectGoal() {
            const params = {
                projectId: this.projectId,
            };
            projectDetailService.getProjectGoal(params).then((res) => {
                this.projectDeliveryGoalItems = res;
            });
        },

        // 绑定新的职位之后，去判断是否创建了交付目标
        isBindNewJob() {
            // 绑定职位成功后，关闭添加职位的弹窗
            this.isAddJob = false;
            // console.log(this.isAddJob);
            this.hasProjectMyDeliveryGoal();
            // 绑定职位成功后，刷新相关职位的列表
            this.init();
        },
        // 判断是否需要弹出创建交付目标的弹框
        hasProjectMyDeliveryGoal() {
            let params = { projectId: this.projectId };
            projectDetailService
                .hasProjectMyDeliveryGoal(params)
                .then((res) => {
                    // console.log(res);
                    // res为false的时候说明没有项目交付目标，需要弹出创建交付目标弹窗
                    if (res == false) {
                        // this.isAddGoal = true;
                        this.addGoal();
                        this.programGoalTitle = true;
                    } else {
                        // this.isAddGoal = false;
                        this.addGoalFinish();
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        // 点击合并到另一项目出现弹窗
        mergeProject() {
            this.$refs.mergeProjectDialog.show();
            // 点击合并项目按钮（出现弹窗）时才调用获取可合并的项目列表
            this.getCanMergeProjects();
        },
        // 获取可合并的项目列表
        getCanMergeProjects() {
            let params = { projectId: this.projectId };
            projectDetailService
                .getCanMergeProjects(params)
                .then((res) => {
                    // console.log(res);
                    if (res.length > 0) {
                        res.forEach((el) => {
                            // console.log(el);
                            // console.log(el.projectName.replace("$#$", "-"));
                            el.projectName = el.projectName.replace("$#$", "-");
                        });
                    }
                    this.mergeProjectArr = res;
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        goToUrl(url, isBlank) {
            if (isBlank) {
                window.open(url);
            } else {
                location.href = url;
            }
        },
    },
};
</script>

<style lang="scss" scope>
@media screen and (max-width: 1400px) {
    .program-detail {
        .main {
            .content {
                width: calc(100% - 370px) !important;
            }
            .side-bar {
                width: 350px !important;
            }
        }
    }
}
@media screen and (min-width: 1400px) and (max-width: 1680px) {
    .program-detail {
        .main {
            .content {
                width: calc(100% - 430px) !important;
            }
            .side-bar {
                width: 410px !important;
            }
        }
    }
}
.program-detail {
    // min-height: calc(100vh - 108px);
    height: 100%;
    overflow-y: auto;
    padding: 20px 20px 30px;
    .top-bar-wrap {
        width: 1200px;
        margin: 0 auto;
        background: #ffffff;
        // height: 225px;
        // max-height: 225px;
        // border-bottom: 1px solid #ececec;
        border-radius: 8px;
    }
    .main {
        width: 1200px;
        // margin: 25px auto 116px;
        margin: 20px auto 0;
        > div {
            vertical-align: top;
        }
        .content {
            display: inline-block;
            width: calc(100% - 470px);
        }
        .side-bar {
            display: inline-block;
            width: 450px;
            margin-left: 20px;
            background-color: #ffffff;
            border-radius: 8px;
        }
    }
}
</style>