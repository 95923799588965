var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "program-side" }, [
    _c(
      "div",
      { staticClass: "side-top-cell" },
      [
        _c("avatar", {
          staticClass: "user-pic",
          attrs: {
            size: "mg",
            src: _vm.topUserInfo.avatarUrl,
            userId: _vm.topUserInfo.userId,
            enableCard: true,
            enableLink: true,
            shareData: _vm.shareData,
          },
        }),
        _c("div", { staticClass: "right-cell-text" }, [
          _c(
            "p",
            {
              staticClass: "line1 ell",
              attrs: {
                title:
                  _vm.topUserInfo.realName + "@" + _vm.topUserInfo.nikeName,
              },
            },
            [
              _c("span", {
                staticClass: "name",
                domProps: { textContent: _vm._s(_vm.topUserInfo.realName) },
              }),
              _vm._v(
                "\n                @" +
                  _vm._s(_vm.topUserInfo.nickname) +
                  "\n            "
              ),
            ]
          ),
          _c(
            "p",
            {
              staticClass: "ell",
              attrs: {
                title: _vm.topUserInfo.firmName + " " + _vm.topUserInfo.title,
              },
            },
            [
              _vm._v(
                _vm._s(_vm.topUserInfo.firmName) +
                  "  " +
                  _vm._s(_vm.topUserInfo.title)
              ),
            ]
          ),
          _c("p", [
            _c("span", { staticClass: "gray" }, [
              _vm._v(_vm._s(_vm.topUserInfo.created) + " 发布"),
            ]),
          ]),
          _c("p", [
            !_vm.isOwner
              ? _c(
                  "button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.contactRecruiter(_vm.topUserInfo.unionId)
                      },
                    },
                  },
                  [_vm._v("联系发布者")]
                )
              : _vm._e(),
          ]),
        ]),
      ],
      1
    ),
    _c("div", { staticClass: "program-news" }, [
      _c("h2", { staticClass: "title" }, [_vm._v("最新动态")]),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.tendLoading,
              expression: "tendLoading",
            },
          ],
          ref: "news",
          staticClass: "news-container",
        },
        [
          _c(
            "ul",
            [
              _vm._l(_vm.list, function (item, index) {
                return _c(
                  "li",
                  { key: "trend" + index, staticClass: "news-item" },
                  [
                    _c("avatar", {
                      staticClass: "pic",
                      attrs: {
                        size: "md",
                        src: item.avatarUrl,
                        userId: item.userId,
                        enableCard: true,
                        enableLink: true,
                        shareData: _vm.shareData,
                      },
                    }),
                    _c("div", { staticClass: "new-content" }, [
                      _c("p", [
                        _c(
                          "span",
                          {
                            staticClass: "name ell",
                            attrs: {
                              title: item.realName + "@" + item.nickname,
                            },
                          },
                          [
                            _c("span", { staticClass: "real-name" }, [
                              _vm._v(_vm._s(item.realName)),
                            ]),
                            _vm._v(
                              "\n                                @" +
                                _vm._s(item.nickname) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _c("span", { staticClass: "time" }, [
                          _vm._v(_vm._s(item.operationTime)),
                        ]),
                      ]),
                      _c("p", {
                        domProps: {
                          innerHTML: _vm._s(_vm.computedDescription(index)),
                        },
                      }),
                    ]),
                  ],
                  1
                )
              }),
              _vm.list.length == 0
                ? _c("div", { staticClass: "empty" }, [_vm._v("暂无数据")])
                : _vm._e(),
              _vm.showMore
                ? _c(
                    "div",
                    {
                      staticClass: "news-bottom",
                      on: { click: _vm.handleShowMore },
                    },
                    [_vm._m(0)]
                  )
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "news-show-more" }, [
      _vm._v("加载更多"),
      _c("i", { staticClass: "el-icon-d-arrow-right" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }