var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "add-job-dialog",
      attrs: {
        title: "添加职位",
        visible: "",
        width: "820px",
        "close-on-click-modal": false,
      },
      on: { close: _vm.handleClose },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loadingList,
              expression: "loadingList",
            },
          ],
          staticClass: "job-dialog-body",
        },
        [
          _c(
            "div",
            { staticClass: "search-bar" },
            [
              _c(
                "el-input",
                {
                  attrs: { placeholder: "请输入职位" },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.handleSearch.apply(null, arguments)
                    },
                  },
                  model: {
                    value: _vm.Keyword,
                    callback: function ($$v) {
                      _vm.Keyword = $$v
                    },
                    expression: "Keyword",
                  },
                },
                [
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-search" },
                    on: { click: _vm.handleSearch },
                    slot: "append",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleClick },
              model: {
                value: _vm.activeTab,
                callback: function ($$v) {
                  _vm.activeTab = $$v
                },
                expression: "activeTab",
              },
            },
            [
              _c("el-tab-pane", {
                attrs: {
                  label: "我的职位",
                  name: "myJob",
                  disabled: _vm.loadingList,
                },
              }),
              _c("el-tab-pane", {
                attrs: {
                  label: "其它职位",
                  name: "other",
                  disabled: _vm.loadingList,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeTab == "myJob",
                  expression: "activeTab == 'myJob'",
                },
              ],
              staticClass: "job-wrap-cell",
            },
            [
              _c(
                "div",
                { ref: "jobsWrap", staticClass: "jobs-wrap" },
                [
                  _vm._l(_vm.my.jobsList, function (jobItem, index) {
                    return _c(
                      "div",
                      { key: "jobs_my" + index, staticClass: "job-item" },
                      [
                        _c("div", { staticClass: "job-l" }, [
                          _c(
                            "p",
                            {
                              staticClass: "item-title ell",
                              attrs: {
                                title:
                                  jobItem.jobName +
                                  " [" +
                                  jobItem.location +
                                  "]",
                              },
                            },
                            [
                              _c("span", { staticClass: "black" }, [
                                _vm._v(_vm._s(jobItem.jobName)),
                              ]),
                              _vm._v(
                                "\n                            [" +
                                  _vm._s(jobItem.location) +
                                  "]\n                        "
                              ),
                            ]
                          ),
                          _c(
                            "p",
                            _vm._l(
                              jobItem.activityTags,
                              function (activityTag, tagIndex) {
                                return _c(
                                  "span",
                                  {
                                    key: "tag_my" + tagIndex,
                                    staticClass: "activity-tag",
                                    class:
                                      activityTag.id == "NewJob"
                                        ? "new-job-tag"
                                        : "",
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(activityTag.name) +
                                        "\n                            "
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]),
                        _c("div", { staticClass: "job-m" }, [
                          _c(
                            "p",
                            {
                              staticClass: "ell",
                              attrs: { title: jobItem.owner },
                              domProps: { textContent: _vm._s(jobItem.owner) },
                            },
                            [
                              _vm._v(
                                "\n                            58同城\n                        "
                              ),
                            ]
                          ),
                          _c("p", [
                            _c("span", { staticClass: "gray" }, [
                              _vm._v(_vm._s(jobItem.created) + " 发布"),
                            ]),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "job-r" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  loading:
                                    _vm.my.isItemLoading &&
                                    _vm.my.currentIndex == index,
                                  disabled:
                                    _vm.my.isItemLoading &&
                                    _vm.my.currentIndex != index,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handldAdd(index)
                                  },
                                },
                              },
                              [_vm._v("添加")]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  _vm.my.jobsList.length == 0
                    ? _c("div", { staticClass: "empty-data" }, [
                        _c("span", { staticClass: "empty-img" }),
                        _c("p", [_vm._v("暂无数据")]),
                      ])
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.my.start,
                      "page-sizes": [10, 30, 50],
                      "page-size": _vm.my.take,
                      layout: "total, prev, pager, next, jumper",
                      total: _vm.my.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.activeTab == "other",
                  expression: "activeTab == 'other'",
                },
              ],
              staticClass: "job-wrap-cell",
            },
            [
              _c(
                "div",
                { ref: "otherWrap", staticClass: "jobs-wrap" },
                [
                  _vm._l(_vm.other.jobsList, function (jobItem, index) {
                    return _c(
                      "div",
                      { key: "jobs_other" + index, staticClass: "job-item" },
                      [
                        _c("div", { staticClass: "job-l" }, [
                          _c(
                            "p",
                            {
                              staticClass: "item-title ell",
                              attrs: {
                                title:
                                  jobItem.jobName +
                                  " [" +
                                  jobItem.location +
                                  "]",
                              },
                            },
                            [
                              _c("span", { staticClass: "black" }, [
                                _vm._v(_vm._s(jobItem.jobName)),
                              ]),
                              _vm._v(
                                "\n                            [" +
                                  _vm._s(jobItem.location) +
                                  "]\n                        "
                              ),
                            ]
                          ),
                          _c(
                            "p",
                            _vm._l(
                              jobItem.activityTags,
                              function (activityTag, tagIndex) {
                                return _c(
                                  "span",
                                  {
                                    key: "tag_other" + tagIndex,
                                    staticClass: "activity-tag",
                                    class:
                                      activityTag.id == "NewJob"
                                        ? "new-job-tag"
                                        : "",
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(activityTag.name) +
                                        "\n                            "
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]),
                        _c("div", { staticClass: "job-m" }, [
                          _c(
                            "p",
                            {
                              staticClass: "ell",
                              attrs: { title: jobItem.owner },
                              domProps: { textContent: _vm._s(jobItem.owner) },
                            },
                            [
                              _vm._v(
                                "\n                            58同城\n                        "
                              ),
                            ]
                          ),
                          _c("p", [
                            _c("span", { staticClass: "gray" }, [
                              _vm._v(_vm._s(jobItem.created) + " 发布"),
                            ]),
                          ]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "job-r" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  loading:
                                    _vm.other.isItemLoading &&
                                    _vm.other.currentIndex == index,
                                  disabled:
                                    _vm.other.isItemLoading &&
                                    _vm.other.currentIndex != index,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handldAdd(index)
                                  },
                                },
                              },
                              [_vm._v("添加")]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  _vm.other.jobsList.length == 0
                    ? _c("div", { staticClass: "empty-data" }, [
                        _c("span", { staticClass: "empty-img" }),
                        _c("p", [_vm._v("暂无数据")]),
                      ])
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.other.start,
                      "page-sizes": [10, 30, 50],
                      "page-size": _vm.other.take,
                      layout: "total, prev, pager, next, jumper",
                      total: _vm.other.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }