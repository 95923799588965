<template>
    <div class="program-list">
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="项目描述" name="describe"></el-tab-pane>
            <el-tab-pane label="相关职位" name="job"></el-tab-pane>
        </el-tabs>
        <pre class="program-describe" v-text="description" v-if="activeName == 'describe'">
        </pre>
        
        
        <!-- <job-list
            v-else
            ref="jobList"
            :jobList="jobList"
            :isMyFirm="isMyFirm"
            :listLoading="loading"
            @updateOrderParams="updateOrderParams"
            @updateJobItem="updateJobItem"
            @refresh="getJobList"
        ></job-list> -->
        <div class="job-list-wrapper" ref="jobList" v-loading="listLoading" v-else>
            <template v-if="jobList.length">
                <job-item
                    ref="jobItem"
                    v-for="(job, index) in jobList"
                    :key="index"
                    :job="job"
                    :isMyFirm="isMyFirm"
                    @changeJobStatus="changeJobStatus"
                ></job-item>
            </template>
            <div class="empty" v-else>
                <span class="empty-img"></span>
                <p class="empty-data">暂无数据</p>
            </div>
            <el-pagination
                :current-page="pager.current"
                :page-sizes="[10, 30, 50]"
                :page-size="pager.take"
                class="job-list-pagination"
                layout="prev, pager, next, slot, total, sizes"
                :total="pager.total"
                v-if="jobList.length > 0"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            >
                <span class="pagination-text">
                    <span>
                        前往
                        <el-input
                            v-model="pagerJump"
                            @keyup.enter.native="handlePagerJump(pagerJump)"
                        ></el-input>
                        页
                    </span>
                    <span @click="handlePagerJump(pagerJump)">
                        跳转
                    </span>
                </span>
            </el-pagination>
        </div>

    </div>
</template>

<script>
import projectDetailService from '#/js/service/projectDetailService.js';
import JobItem from '../component/job-item.vue';

export default {
    name: 'program-list',
    props: {
        programListData: {
            type: Object,
            default: () => {},
        },
    },
    components: {
        JobItem,
    },
    data() {
        return {
            activeName: 'describe',
            jobList: [],
            loading: false,
            isMyFirm: '',

            listLoading: false,

            pager: {
                current: 1,
                take: 10,
                total: 0
            },
            pagerJump: 0,
        };
    },
    created() {
        this.getProjectJobPage();
    },
    computed: {
        description() {
            return this.programListData.description;
        },
        projectId() {
            return this.$route.params.projectId;
        }
    },
    mounted() {

    },
    methods: {
        handleClick() {

        },
        // 获取职位页
        getProjectJobPage(needScroll=false) {
            const params = {
                start: (this.pager.current-1) * this.pager.take,
                take: this.pager.take,
                projectId: this.projectId,
            };
            this.listLoading = true;
            projectDetailService.getProjectJobPage(params).then(res => {
                this.jobList = res.list;
                this.pager.total = res.total;
                this.$emit('get-project-job-finish',this.jobList);
            }).finally(() => {
                this.listLoading = false;
                if(needScroll) {
                    this.$nextTick(() => {
                        this.$refs.jobList && this.$refs.jobList.querySelector('.job-item') && this.$refs.jobList.querySelector('.job-item').scrollIntoView(true);
                    })
                }
            });
        },
        changeJobStatus(job) {
            this.$emit('program-list-deal-finish');
        },
        handleSizeChange(val) {
            this.pager.take = val;
            this.pager.current = 1;
            // this.search(true, 0);
            this.getProjectJobPage(true);
        },
        handleCurrentChange(current) {
            this.pager.current = current;
            // this.search(true, 0);
            this.getProjectJobPage(true);
        },
        handlePagerJump(pagerJump) {
            // if(pagerJump > Math.ceil(this.pager.total / this.pager.size)) {
            //     return;
            // } else {
            //     this.handleCurrentChange(pagerJump);
            // }
            pagerJump = Number.parseInt(pagerJump);
            if (
                pagerJump > 0 &&
                pagerJump <= Math.ceil(this.pager.total / this.pager.take)
            ) {
                this.handleCurrentChange(pagerJump);
            }
        },

        updateOrderParams(params) {
            Object.assign(this.orderParams, params);
        },
        updateJobItem(job) {
            let jobIndex = this.jobList.findIndex(item => job.id === item.id);
            this.$set(this.jobList, jobIndex, job);
        },
        goToUrl(url, isBlank) {
            if(isBlank) {
                window.open(url);
            } else {
                location.href = url;
            }
        }
    }
};
</script>

<style lang="scss">
.program-list {
    .el-tabs__header {
        .el-tabs__item {
            height: 60px;
            line-height: 65px;
        }
        .el-tabs__nav-wrap {
            padding: 0 45px;
        }
        .el-tabs__active-bar {
            height: 4px;
        }
    }
}
</style>

<style lang="scss" scope>
.program-list{
    background-color: #ffffff;
    margin-top: 20px;
    border-radius: 8px;

    .program-describe{
        font-family: "Microsoft Yahei";
        box-sizing: border-box;
        padding: 15px 28px 30px;
        color: #333;
        font-size: 14px;
        line-height: 28px;
        white-space: pre-wrap;
        word-wrap: break-word;
    }

    .job-list-wrapper {
        min-height: 200px;
        .empty {
            min-height: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .empty-img {
                display: inline-block;
                background: url('~@src/assets/images/home/icon_no_data.svg') no-repeat;
                background-size: contain;
                width: 170px;
                height: 160px;
            }
            .empty-data {
                font-size: 14px;
                margin-bottom: 0px;
            }
        }
        .job-list-pagination.el-pagination {
            // margin: 40px 0 20px 0;
            padding: 40px 28px 28px;
            font-weight: normal;
            .btn-next, .btn-prev {
                height: 38px;
                border: 1px solid;
            }
            .btn-prev {
                border-right: none;
                border-radius: 4px 0 0 4px;
            }
            .btn-next {
                border-left: none;
                border-radius: 0 4px 4px 0;
            }
            .el-pagination__sizes .el-input{
                width: 87px;
                .el-input__inner {
                    color: #666;
                    .el-select__caret{
                        color: #999;
                    }
                }
            }
            .el-pager {
                border-top: 1px solid;
                border-bottom: 1px solid;
                li {
                    min-width: 36px;
                    height: 36px;
                    line-height: 36px;
                    font-size: 14px;
                }
            }
            .btn-next, .btn-prev, .el-pager {
                border-color: #DDDDDD;
            }
            .el-input__inner, .el-pagination__total, .el-pagination__jump {
                height: 38px;
                line-height: 38px;
                font-size: 14px;
            }
            .pagination-text{
                color: #999;
                span{
                    height: 38px;
                    line-height: 38px;
                    font-size: 14px;
                    .el-input{
                        width: 48px;
                        margin: 0 5px;
                        &__inner{
                            height: 38px;
                            line-height: 38px;
                            padding: 0 5px;
                        }
                    }
                    &:nth-of-type(2){
                        margin-left: 5px;
                        color: $primary;
                        cursor: pointer;
                    }
                }
            }
            .el-pagination__total,
            .el-pagination__sizes,
            .pagination-text {
                float: right;
            }
        }
    }
}


</style>