<template>
    <div class="program-goal">
        <div class="goal-title">
            <span>我的项目交付目标</span>
            <i v-if="goalLists.length>0 && isBindJob" class="add-program-goal" @click="addGoal">+项目交付目标</i>
        </div>
        <div class="goal-list-wrap "
            :class="{'spread': isSpread}"
            v-if="goalLists.length>0"
            >
            <!-- :class="{'no-border': index == goalLists.length-1}" -->
            <div class="goal-list" 
                v-for="(item, index) in goalLists" 
                :key="`goal_`+index">
                <div class="cell-l">
                    <span class="empty"></span>
                    <span class="break-wrap">
                        <span class="handover" v-if="item.projectDeliveryType!=0">交接</span><br>
                        <span class="date" v-text="item.monthly">2021-04</span>
                    </span>
                </div>
                <div class="cell-m">
                    <div class="cell-item">
                        <span class="goal-text">月推荐目标：
                            <i class="green" v-text="item.goalOfRecommandation">100</i>
                        </span>
                    </div>
                    <div class="cell-item">
                        <span class="goal-text">实际月推荐：
                            <i class="green" v-if="item.realOfRecommandation >= item.goalOfRecommandation" v-text="item.realOfRecommandation">100</i>
                            <i class="orange" v-else v-text="item.realOfRecommandation">100</i>
                        </span>
                        <span class="pop get" v-if="item.realOfRecommandation >= item.goalOfRecommandation"><i class="el-icon-success"></i>已达标</span>
                        <span class="pop no-get" v-else><i class="el-icon-warning"></i>未达标</span>
                    </div>
                </div>

                <div class="cell-m"><div class="cell-item">
                        <span class="goal-text">月面试目标：
                            <i class="green" v-text="item.goalOfInterview">100</i>
                        </span>
                    </div>
                    <div class="cell-item">
                        <span class="goal-text">实际月面试：
                            <i class="green" v-if="item.realOfInterview >= item.goalOfInterview" v-text="item.realOfInterview">100</i>
                            <i class="orange" v-else v-text="item.realOfInterview">100</i>
                        </span>
                        <span class="pop get" v-if="item.realOfInterview >= item.goalOfInterview"><i class="el-icon-success"></i>已达标</span>
                        <span class="pop no-get" v-else><i class="el-icon-warning"></i>未达标</span>
                    </div>
                </div>

                <div class="cell-m"><div class="cell-item">
                        <span class="goal-text">月offers目标：
                            <i class="green" v-text="item.goalOfOffer">100</i>
                        </span>
                    </div>
                    <div class="cell-item">
                        <span class="goal-text">实际月offers：
                            <i class="green" v-if="item.realOfOffer >= item.goalOfOffer" v-text="item.realOfOffer">100</i>
                            <i class="orange" v-else v-text="item.realOfOffer">100</i>
                        </span>
                        <span class="pop get"  v-if="item.realOfOffer >= item.goalOfOffer"><i class="el-icon-success"></i>已达标</span>
                        <span class="pop no-get" v-else><i class="el-icon-warning"></i>未达标</span>
                    </div>
                </div>
                <div class="cell-r">
                    <a href="javascript:;" 
                        class="edit-btn" 
                        v-if="canEdit(item.monthly)"
                        @click="editGoal(index)">
                        <!-- <svg class="icon edit-goal-list" aria-hidden="true">
                            <use xlink:href="#icon-edit" class="tx-size"></use>
                        </svg> -->
                        <img src="~@src/assets/images/program/edit_icon_1.png" alt="">
                        编辑
                    </a>
                </div>
            </div>
        </div>

        <div class="empty-data" v-else>
            <span class="empty-img ">
                <img src="~@src/assets/images/program/goal-empty.png" alt="">
                <span>还没有添加项目交付目标，<br>赶紧去添加吧！<br><i class="add-program-goal" @click="addGoal" v-if="isBindJob">+项目交付目标</i></span>
            </span>
            <!-- <p>暂无数据</p> -->
        </div>

        <a class="draw-switch"
            @click="switchDraw(0)"
            v-if="isSpread && goalLists.length>1">
            收起多余
            <i class="draw-icon up"></i>
        </a>
        <a class="draw-switch" v-if="!isSpread && goalLists.length>1" @click="switchDraw(1)">
            展开更多
            <i class="draw-icon"></i>
        </a>

    </div>
</template>

<script>
import moment from 'moment';

export default {
    name: 'my-program-goal',
    props: {
        isBindJob: Boolean,
        goalData: {
            type: Array,
            default: () => [],
        }
    },
    components: {
    },
    data() {
        return {
            isSpread: false, // 是否展开
        };
    },
    mounted() {
    },
    computed: {
        goalLists() {
            return this.goalData;
        }
    },
    methods: {
        addGoal() {
            this.$emit("add-goal");
        },
        // 编辑目录(当月才能编辑)
        editGoal(index) {
            this.$emit('eidt-goal', index);
        },

        // 判断是否可编辑
        canEdit(monthly) {
            const _now = moment().format('YYYY-MM');
            return moment(_now).isSameOrBefore(monthly);
        },
        switchDraw(type) {
            if(type == 0) {
                this.isSpread = false;
            }else{
                this.isSpread = true;
            }
        },
        goToUrl(url, isBlank) {
            if(isBlank) {
                window.open(url);
            } else {
                location.href = url;
            }
        }
    }
};
</script>

<style lang="scss" scope>
.program-goal{
    span,p,div,i,button,a{
        font-family: "Microsoft YaHei";
    }
    div,button,span{
        box-sizing: border-box;
    }
    background: #ffffff;
    box-sizing: border-box;
    padding-bottom: 28px;
    position: relative;
    border-radius: 8px;
    .goal-title{
        height: 70px;
        line-height: 69px;
        border-bottom: 1px solid #eee;
        .add-program-goal {
            cursor: pointer;
            float: right;
            margin-right: 20px;
            font-size: 14px;
            color: #38BC9D;
        }
        >span{
            font-size: 16px;
            font-weight: bold;
            color: #333333;
            &::before{
                content: '';
                display: inline-block;
                width: 8px;
                height: 20px;
                background: #38BC9D;
                margin-right: 12px;
                vertical-align: text-bottom;
            }
        }
    }
    .goal-list-wrap{
        height: auto;
        // max-height: 93px;
        max-height: 112px;
        overflow: hidden;
        .goal-list{
            width: 100%;
            // height: 93px;
            height: 112px;
            border-bottom: 1px solid transparent;
            vertical-align: top;
            font-size: 0;
            // padding: 22px 0 20px;
            padding: 30px;
            display: flex;
            justify-content: space-between;
            &:last-child{
                border-bottom: 1px solid transparent;
            }
            >div{
                display: inline-block;
                vertical-align: top;
            }
            .cell-l{
                width: 112px;
                height: 50px;
                text-align: center;
                font-size: 0;
                .empty{
                    height: 100%;
                    display: inline-block;
                    vertical-align: middle;
                }
                .break-wrap{
                    display: inline-block;
                    vertical-align: middle;
                    .handover{
                        display: inline-block;
                        font-size: 14px;
                        font-weight: 400;
                        color: #38BC9D;
                        line-height: 18px;
                        padding: 0 6px;
                        border: 1px solid #38BC9D;
                        border-radius: 9px;
                        box-sizing: border-box;
                        margin-bottom: 5px;
                    }
                    .date{
                        font-size: 14px;
                        font-weight: 400;
                        color: #333333;
                    }
                }
            }
            .cell-m{
                width: 208px;
                .cell-item{
                    width: 100%;
                    height: 20px;
                    &:first-child{
                        margin-bottom: 10px;
                    }
                    .goal-text{
                        font-size: 14px;
                        font-weight: 400;
                        color: #333333;
                        >i{
                            font-style: normal;
                        }
                        .green{
                            color: #38BC9D;
                        }
                        .orange{
                            color: #FF6564;
                        }
                    }
                    .pop{
                        display: inline-block;
                        padding: 3px 8px 3px 4px;
                        border-radius: 10px;
                        font-size: 14px;
                        font-weight: 400;
                        margin-left: 10px;
                        >i{
                            margin: 0 4px;
                            width: 14px;
                            height: 14px;
                        }
                    }
                    .get{
                        color: #38BC9D;
                        background: #E0FAF4;
                    }
                    .no-get{
                        color: #FF6564;
                        background: #FDE4E4;
                    }
                }
            }
            .cell-r{
                width: 86px;
                height: 47px;
                line-height: 47px;
                text-align: center;
                .edit-btn{
                    display: none;
                    font-size: 14px;
                    font-weight: 400;
                    color: #38BC9D;
                    .edit-goal-list{
                        width: 16px;
                        height: 16px;
                        margin-right: 6px;
                    }
                    >img{
                        width: 16px;
                        height: 16px;
                        margin-right: 6px;
                        vertical-align: middle;
                    }
                }
            }
            &:hover{
                .cell-r{
                    .edit-btn{
                        display: inline-block;
                    }
                }
            }
        }
    }
    .spread{
        max-height: 465px;
        overflow: auto;
        .goal-list{
            border-bottom: 1px solid #eee;
            &:last-child{
                border-bottom: 1px solid transparent;
            }
        }
    }
    .no-border{
        border-bottom: 1px solid transparent;
    }
    .empty-data{
        text-align: center;
        .empty-img{
            display: inline-block;
            box-sizing: border-box;
            padding-top: 24px;
            >img{
                width: 82px;
                height: 70px;
                margin-right: 30px;
                vertical-align: middle;
            }
            >span{
                display: inline-block;
                font-size: 14px;
                font-weight: 400;
                color: #666;
                line-height: 24px;
                vertical-align: middle;
                text-align: left;
            }
            .add-program-goal {
                cursor: pointer;
                display: inline-block;
                margin-top: 10px;
                font-size: 14px;
                color: #38BC9D;
            }
        }
    }

    .draw-switch{
        position: absolute;
        right: 21px;
        // bottom: 10px;
        bottom: 30px;
        z-index: 10;
        font-size: 14px;
        font-weight: 400;
        color: #38BC9D;
        cursor: pointer;
        &:active,&:hover{
            color: #38BC9D;
        }
        .draw-icon{
            display: inline-block;
            width: 12px;
            height: 12px;
            background-size: 100% 100%;
            background: url("~@src/assets/images/program/show_more.png") no-repeat;
            margin-left: 7px;
            vertical-align: middle;
        }
        .up{
            transform: rotate(180deg);
        }
    }
}


</style>