var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "top-bar-project" }, [
    _c("div", { staticClass: "top-bar-left" }, [
      _c("div", { staticClass: "top-l" }, [
        _c("div", { staticClass: "logo-wrap" }, [
          _c("img", {
            attrs: {
              src:
                _vm.baseData.firmLogoUrl ||
                "//hstatic.hirede.com/lbd/images/firm-default-avatar.jpg",
            },
          }),
        ]),
        _c(
          "p",
          {
            staticClass: "company-name ell",
            attrs: { title: _vm.baseData.customerName },
          },
          [
            _vm._v(
              "\n                " +
                _vm._s(
                  _vm.baseData.customerName ? _vm.baseData.customerName : ""
                ) +
                "\n            "
            ),
          ]
        ),
      ]),
      _c("div", { staticClass: "top-m" }, [
        _c(
          "div",
          {
            staticClass: "program-name ell",
            attrs: {
              title: _vm.baseData.name
                ? _vm.baseData.name.replaceAll("$#$", "-")
                : "",
            },
          },
          [
            _vm._v(
              "\n                " +
                _vm._s(
                  _vm.baseData.name
                    ? _vm.baseData.name.replaceAll("$#$", "-")
                    : ""
                ) +
                "\n            "
            ),
          ]
        ),
        _c("p", [
          _c(
            "span",
            {
              staticClass: "company ell",
              attrs: { title: _vm.baseData.customerName },
            },
            [
              _vm._v(
                _vm._s(
                  _vm.baseData.customerName ? _vm.baseData.customerName : "未知"
                )
              ),
            ]
          ),
          _c(
            "span",
            {
              staticClass: "local ell",
              attrs: { title: _vm.computedCitys(_vm.baseData.locationIds) },
              domProps: {
                textContent: _vm._s(
                  _vm.computedCitys(_vm.baseData.locationIds)
                ),
              },
            },
            [_vm._v("深圳市、西安市、成都市、上海市")]
          ),
        ]),
        _c("p", [
          _c("span", { staticClass: "depart" }, [
            _vm._v(
              _vm._s(
                _vm.baseData.customerDeparmentName
                  ? _vm.baseData.customerDeparmentName
                  : "未知"
              )
            ),
          ]),
        ]),
        _c("p", { staticClass: "delivery-info" }, [
          _c("span", [
            _vm._v("HC数量："),
            _c("i", [
              _vm._v(
                _vm._s(_vm.baseData.headcount ? _vm.baseData.headcount : 0)
              ),
            ]),
          ]),
          _c("span", [
            _vm._v("\n                    乐观程度：\n                    "),
            _vm.baseData.optimisticDegree == 0
              ? _c("i", [_vm._v("-")])
              : _c("i", [
                  _vm._v(
                    _vm._s(
                      _vm.baseData.optimisticDegree == "1" ||
                        _vm.baseData.optimisticDegree == "2"
                        ? _vm.baseData.optimisticDegree == "1"
                          ? "乐观"
                          : "不乐观"
                        : "谨慎"
                    )
                  ),
                ]),
          ]),
          _c("span", [
            _vm._v("\n                    节点重要性：\n                    "),
            _vm.baseData.nodeImportance == 0
              ? _c("i", [_vm._v("-")])
              : _c("i", [
                  _vm._v(
                    _vm._s(_vm.baseData.nodeImportance == "1" ? "A类" : "B类")
                  ),
                ]),
          ]),
          _vm.isCFUser && _vm.baseData.accessDate
            ? _c("span", [
                _vm._v("我接入的时间："),
                _c("i", [
                  _vm._v(
                    _vm._s(_vm._f("accessDateFilter")(_vm.baseData.accessDate))
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.isCFUser && !_vm.baseData.accessDate
            ? _c("span", [_vm._v("我接入的时间："), _c("i", [_vm._v("-")])])
            : _vm._e(),
        ]),
        _vm.canSeeHrInfos && _vm.baseData.hrName
          ? _c("div", { staticClass: "hr-card" }, [
              _c(
                "p",
                { staticClass: "p1" },
                [
                  _vm._v(
                    _vm._s(_vm.baseData.hrName || "-") +
                      " " +
                      _vm._s(_vm.baseData.hrPhone) +
                      " \n                    "
                  ),
                  _c(
                    "el-tooltip",
                    {
                      attrs: {
                        effect: "light",
                        content: "HR 相关信息为私密信息，仅自己可见",
                        placement: "right",
                      },
                    },
                    [
                      _c("el-button", { attrs: { type: "text secret" } }, [
                        _c("i", { staticClass: "el-icon-unlock secret" }),
                        _vm._v("私密\n                        "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("p", { staticClass: "p2" }, [
                _c("span", { staticClass: "cel" }, [
                  _vm._v(
                    "HR" +
                      _vm._s(_vm.getContactTypeText(_vm.baseData.hrWeChat)) +
                      "：" +
                      _vm._s(_vm.getWeChat(_vm.baseData.hrWeChat))
                  ),
                ]),
                _c("span", { staticClass: "cel" }, [
                  _vm._v("HR职位：" + _vm._s(_vm.baseData.hrTitle || "-")),
                ]),
                _c("span", { staticClass: "cel" }, [
                  _vm._v("HR邮箱：" + _vm._s(_vm.baseData.hrEmail || "-")),
                ]),
              ]),
            ])
          : _vm._e(),
        _c("div", { staticClass: "total-data" }, [
          _c("span", [
            _vm._v("推荐"),
            _c("i", [
              _vm._v(
                _vm._s(
                  _vm.baseData.recommendCount ? _vm.baseData.recommendCount : 0
                )
              ),
            ]),
          ]),
          _c("span", [
            _vm._v("面试"),
            _c("i", [
              _vm._v(
                _vm._s(
                  _vm.baseData.interviewCount ? _vm.baseData.interviewCount : 0
                )
              ),
            ]),
          ]),
          _c("span", [
            _vm._v("offer"),
            _c("i", [
              _vm._v(
                _vm._s(_vm.baseData.offerCount ? _vm.baseData.offerCount : 0)
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "top-bar-right" }, [
      _c("div", { staticClass: "top-r" }, [
        _c("div", { staticClass: "btn-wrap" }, [
          _c("button", { on: { click: _vm.addJob } }, [_vm._v("添加职位")]),
          _vm.isBindJob
            ? _c("button", { on: { click: _vm.updateDeliveryInfomation } }, [
                _vm._v("更新交付信息"),
              ])
            : _vm._e(),
        ]),
        _vm.baseData.isOwner || _vm.userInfo.isAdministrator
          ? _c("div", { staticClass: "btn-wrap" }, [
              _c(
                "a",
                {
                  attrs: { href: "javascript:;" },
                  on: { click: _vm.editProgram },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@src/assets/images/program/edit_icon.png"),
                      alt: "",
                    },
                  }),
                  _vm._v("编辑"),
                ]
              ),
              _c(
                "a",
                {
                  attrs: { href: "javascript:;" },
                  on: { click: _vm.deleteProgram },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@src/assets/images/program/delete_icon.png"),
                      alt: "",
                    },
                  }),
                  _vm._v("删除"),
                ]
              ),
              _c(
                "a",
                {
                  attrs: { href: "javascript:;" },
                  on: { click: _vm.mergeProject },
                },
                [
                  _c("i", { staticClass: "el-icon-copy-document" }),
                  _vm._v(
                    "\n                    合并到另一项目\n                "
                  ),
                ]
              ),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }