<template>
    <el-dialog
        title="合并到另一项目"
        width="600px"
        :show-close="false"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        v-loading="mergeLoading"
    >
        <p class="merge-tip">
            合并后，该项目所有信息将合并为你所选中的项目信息。且所属的所有职位将归属被选中的项目。
        </p>
        <el-form :model="mergeForm" ref="mergeForm" :rules="rules">
            <el-form-item prop="mergeId" required>
                <el-select
                    v-model="mergeForm.mergeId"
                    filterable
                    clearable
                    placeholder="搜索项目名称"
                >
                    <el-option
                        v-for="item in mergeProjectArr"
                        :key="item.projectId"
                        :label="item.projectName"
                        :value="item.projectId"
                    >
                    </el-option>
                </el-select>
            </el-form-item>
        </el-form>

        <!-- <el-form :model="mergeForm" ref="mergeForm" :rules="rules">
            <el-form-item prop="mergeId">
                <el-autocomplete
                    style="width: 100%"
                    v-model="mergeForm.mergeId"
                    :fetch-suggestions="querySearchAsync"
                    placeholder="搜索项目名称"
                    @select="handleSelect"
                >
                </el-autocomplete>
            </el-form-item>
        </el-form> -->

        <div slot="footer">
            <el-button
                type="primary"
                @click="handleProjectMerge"
                :loading="commitLoadig"
                >确定</el-button
            >
            <el-button @click="handleCloseDialog" :loading="commitLoadig">取消</el-button>
        </div>
    </el-dialog>
</template>

<script>
// import shortTips from "@/js/plugin/jquery.setting";
import projectDetailService from "#/js/service/projectDetailService.js";

export default {
    name: "add-activity",
    props: {
        mergeProjectArr: {
            type: Array,
            default: [],
        },
        projectId: {
            type: String,
            default: undefined,
        },
    },
    components: {},
    data() {
        return {
            commitLoadig: false,
            mergeForm: {
                mergeId: "",
            },
            dialogVisible: false,
            mergeLoading: false,
            rules: {
                // mergeId: [
                //     {
                //         required: true,
                //         message: "请搜索项目名称",
                //         trigger: "blur",
                //     },
                // ],
                mergeId: [
                    {
                        required: true,
                        message: "请搜索项目名称",
                        trigger: "change",
                    },
                ],
            },
        };
    },
    created() {
        // console.log(this.mergeProjectArr)
    },
    mounted() {},
    methods: {
        show() {
            this.dialogVisible = true;
            this.resetForm();
        },
        // 清空表单验证
        resetForm() {
            if (this.$refs["mergeForm"] !== undefined) {
                this.$refs["mergeForm"].resetFields();
            }
        },
        // querySearchAsync(searchText, cb) {
        //     var mergeProjectArr = this.mergeProjectArr;
        //     // 解决element建议搜索框无法显示内容 的数据处理
        //     for (var i = 0; i < mergeProjectArr.length; i++) {
        //         mergeProjectArr[i].value = mergeProjectArr[i].projectName;
        //     }
        //     var results = searchText
        //         ? mergeProjectArr.filter(this.createFilter(searchText))
        //         : mergeProjectArr;
        //     // 调用 callback 返回建议列表的数据
        //     cb(results);
        //     // cb();
        // },
        // createFilter(searchText) {
        //     return (mergeProject) => {
        //         return (
        //             mergeProject.value
        //                 .toLowerCase()
        //                 .indexOf(searchText.toLowerCase()) > 0
        //         );
        //     };
        // },
        // 点击选项选择项目
        // handleSelect(item) {
        //     this.$refs["mergeForm"].validate((valid) => {
        //         if (valid) {
        //             console.log(item);
        //         }
        //     });
        // },
        // 点击确定按钮：提交
        handleProjectMerge() {
            this.$refs["mergeForm"].validate((valid) => {
                // console.log(this.mergeForm.mergeId);
                if (valid) {
                    // console.log("点击合并提交按钮");
                    let params = {
                        MergeProjectId: this.mergeForm.mergeId,
                        DeleteProjectId: this.projectId,
                    };
                    this.commitLoadig = true;
                    // console.log(params.MergeProjectId);
                    // console.log(params.DeleteProjectId);
                    projectDetailService
                        .commitMergeProjects(params)
                        .then((res) => {
                            // console.log(res);
                            shortTips("合并成功");
                            // this.$message({
                            //     message: "合并成功",
                            //     type: "success",
                            // });
                            this.dialogVisible = false;
                            this.commitLoadig = false;
                            // 合并成功后跳转到项目主页
                            // location.href = `/Headhunting/MyCompany.html#/project`;
                            location.href = `/#/project`;
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            });
        },
        // 点击取消关闭弹窗
        handleCloseDialog() {
            this.dialogVisible = false;
            this.mergeForm.mergeId = null;
        },
    },
};
</script>

<style lang="scss">
// element 提示框样式
.el-message.el-message--success {
    background-color: #f0f9eb;
    border-color: #e1f3d8;
    .el-icon-success {
        color: #67c23a;
    }
    .el-message__content {
        color: #67c23a;
    }
}
</style>

<style lang="scss" scope>
.merge-tip {
    font-size: 14px;
    color: #666666;
    line-height: 24px;
}
</style>