<template>
    <div class="program-side">
        <div class="side-top-cell">
            <!-- <div class="user-pic"> -->
                <!-- <img src="" alt=""> -->
                <!-- <img src="~@src/assets/images/default-user.png" alt=""> -->
                <avatar
                    class="user-pic"
                    size="mg"
                    :src="topUserInfo.avatarUrl"
                    :userId="topUserInfo.userId"
                    :enableCard="true"
                    :enableLink="true"
                    :shareData="shareData"
                ></avatar>
            <!-- </div> -->
            <div class="right-cell-text">
                <p class="line1 ell" :title="topUserInfo.realName + '@' + topUserInfo.nikeName">
                    <span class="name" v-text="topUserInfo.realName"></span>
                    @{{topUserInfo.nickname}}
                </p>
                <p class="ell" :title="topUserInfo.firmName + ' ' + topUserInfo.title">{{topUserInfo.firmName}}  {{topUserInfo.title}}</p>
                <p><span class="gray">{{topUserInfo.created}} 发布</span></p>
                <p>
                    <button @click="contactRecruiter(topUserInfo.unionId)" v-if="!isOwner">联系发布者</button>
                </p>
            </div>
        </div>

        <div class="program-news">
            <h2 class="title">最新动态</h2>
            <div ref="news" class="news-container" v-loading="tendLoading">
                <ul>
                    <li class="news-item" 
                        v-for="(item, index) in list" 
                        :key="'trend'+index">
                        <avatar
                            class="pic"
                            size="md"
                            :src="item.avatarUrl"
                            :userId="item.userId"
                            :enableCard="true"
                            :enableLink="true"
                            :shareData="shareData"
                        ></avatar>
                        <div class="new-content">
                            <p>
                                <span class="name ell" :title="item.realName + '@' + item.nickname">
                                    <span class="real-name">{{item.realName}}</span>
                                    @{{item.nickname}}
                                </span>
                                <span class="time">{{item.operationTime}}</span>
                            </p>
                            <p v-html="computedDescription(index)"></p>
                        </div>
                    </li>
                    <div class="empty" v-if="list.length==0">暂无数据</div>
                    <div class="news-bottom"
                        v-if="showMore"
                        @click="handleShowMore">
                        <span class="news-show-more">加载更多<i class="el-icon-d-arrow-right"></i></span>
                    </div>
                </ul>
            </div>

        </div>
    </div>
</template>

<script>
import moment from 'moment';
import emitter from '@src/js/mixins/emitter';
import Avatar from '#/component/common/avatar.vue';
import projectDetailService from '#/js/service/projectDetailService.js';

export default {
    name: 'programDetailRightSide',
    mixins: [emitter],
    components: {
        Avatar,
    },
    props: {
        userInfo: {
            type: Object,
            default: () => {}
        },
        shareData: {
            type: Object,
            default: () => {}
        },
        isOwner: {
            type: Boolean,
            default: false,
        },
        projectName: {
            type: String,
            default: '',
        },
        baseData: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            tendLoading: false,
            start: 0,
            take: 5,
            total: 0,
            list: [], // 动态列表数据
        };
    },
    computed: {
        topUserInfo() {
            return this.userInfo || {};
        },
        projectId() {
            return this.$route.params.projectId;
        },
        showMore() {
            return this.total > this.start * this.take;
        },
    },
    filters: {
        formatAvatar(url) {
            if(NODE_ENV && (NODE_ENV == 'dev' || NODE_ENV == 'rc') && url) {
                url = url.replace('www.rc.liebide.com', 'portalapi.rc.liebide.com');
            }
            return url;
        }
    },
    mounted() {
        this.requestLatestTrends();
    },
    methods: {
        computedDescription(index) {
            const _list = this.list[index];
            // console.log(_list);
            // console.log(_list.extensionProperties.projectName);
            if(_list.extensionProperties.projectName){
                // 切割项目名称的 $#$ 符号
                _list.extensionProperties.projectName = _list.extensionProperties.projectName.replace("$#$", "-");
            }
            switch(_list.activityStreamType) {
                case 1000:
                    // console.log(this.projectName);
                    const addGoalProjectName = this.projectName.replace("$#$", "-");
                    // console.log(addGoalProjectName);
                    return `添加了 <span class="green">${addGoalProjectName}</span> 交付目标 。`;
                case 1001:
                    // return `添加了 <a class="green" href="/Headhunting/MyCompany.html#/Job/${_list.extensionProperties.jobId}" target="_blank" title="${_list.extensionProperties.jobTitle}">${_list.extensionProperties.jobTitle}</a> 职位 。`;
                    return `添加了 <a class="green" href="/Headhunting/MyCompany.html#/Job/${_list.extensionProperties.jobId}" target="_blank" title="${_list.extensionProperties.jobTitle}">${_list.extensionProperties.jobTitle}</a> 职位 。`;
                case 1002:
                    let returnStr = '';
                    // 当返回的项目名称的json数据里面有\n（换行）时，要先转义：'\\'转成单个'\','\\n'转成'\n',最后转成'\\n'这样就可以用parse转成对象时变为'\n'，取出的字符串设置到html文本中\n被解析为换行
                    _list.extensionProperties.updateProjectContent = _list.extensionProperties.updateProjectContent.replace(/\n/g,"\\\\n").replace(/\r/g,"\\\\r");
                    // console.log(_list.extensionProperties.updateProjectContent);
                    // console.log(JSON.parse(_list.extensionProperties.updateProjectContent));
                    try{
                        const _updateContentObj = JSON.parse(_list.extensionProperties.updateProjectContent);
                        if(_updateContentObj.hasOwnProperty('CustomerName')) {
                            returnStr += `将客户修改成 <span class="green">${_updateContentObj.CustomerName}</span> ，`;
                        }
                        if(_updateContentObj.hasOwnProperty('CustomerDeparmentName')) {
                            returnStr += `将部门修改成 <span class="green">${_updateContentObj.CustomerDeparmentName}</span> ，`;
                        }
                        if(_updateContentObj.hasOwnProperty('Location')) {
                            returnStr += `将所在地区修改成 <span class="green">${_updateContentObj.Location}</span> ，`;
                        }

                        // 交付信息动态文案
                        if(_updateContentObj.hasOwnProperty('Headcount') || _updateContentObj.hasOwnProperty('OptimisticDegree') || _updateContentObj.hasOwnProperty('NodeImportance') || _updateContentObj.hasOwnProperty('AccessDate')) {
                            returnStr += `更新了 <span class="green">
                                <i>
                                    ${_updateContentObj.Headcount ? `HC数量为${_updateContentObj.Headcount}` : ''}
                                    ${_updateContentObj.Headcount && (_updateContentObj.OptimisticDegree || _updateContentObj.NodeImportance || _updateContentObj.AccessDate) ? '、' : ''}
                                </i> 
                                <i>
                                    ${_updateContentObj.OptimisticDegree ? `乐观程度为${(_updateContentObj.OptimisticDegree=='1' || _updateContentObj.OptimisticDegree=='2') ? (_updateContentObj.OptimisticDegree=='1' ? '乐观' : '不乐观') : '谨慎'}` : ''}
                                    ${_updateContentObj.OptimisticDegree && (_updateContentObj.NodeImportance || _updateContentObj.AccessDate) ? '、' : ''}
                                </i>
                                <i>
                                    ${_updateContentObj.NodeImportance ? `节点重要性为${_updateContentObj.NodeImportance == '1' ? 'A类' : 'B类'}` : ''}
                                    ${_updateContentObj.NodeImportance && _updateContentObj.AccessDate ? '、' : ''}
                                </i>
                                <i>
                                    ${_updateContentObj.AccessDate ? `接入时间为${moment(_updateContentObj.AccessDate).format('YYYY-MM-DD')}` : ''}
                                </i>
                            `
                        }

                        if(_updateContentObj.hasOwnProperty('HRTitle') || 
                            _updateContentObj.hasOwnProperty('HRWeChat') || 
                            _updateContentObj.hasOwnProperty('HRName') || 
                            _updateContentObj.hasOwnProperty('hrPhone') ||
                            _updateContentObj.hasOwnProperty('hrEmail') 
                        ) {
                            returnStr += `更新了 <span class="green">HR 相关信息</span> 的内容 。`;
                        }

                        const editProjectKeyMap = {
                            Name: '项目名称',
                            // Headcount: '招聘人数',
                            Description: '项目描述',
                        };
                        let _otherStr = '';
                        let _otherArr = [];
                        Object.keys(editProjectKeyMap).forEach(item => {
                            if(_updateContentObj.hasOwnProperty(item)){
                                _otherArr.push(editProjectKeyMap[item]);
                            }
                        });
                        // console.log(_otherArr);
                        if(_otherArr.length >0){
                            _otherStr = _otherArr.join('、');
                            returnStr += `修改了 <span class="green">${_otherStr}</span> 的内容 。`;
                        } else {
                            returnStr = returnStr.substr(0, returnStr.length-1) + ' 。';
                        }
                    } catch(e) {
                        returnStr += `修改了 <span class="green">${_list.extensionProperties.updateProjectContent}</span> 的内容 。`;
                    }
                    
                    // '客户名称你好、'.match(/客户\S*、/g)
                    return returnStr;
                case 1003:
                    // return `删除了项目中的 <a class="green" href="/Headhunting/MyCompany.html#/Job/${_list.extensionProperties.jobId}" target="_blank" title="${_list.extensionProperties.jobTitle}">${_list.extensionProperties.jobTitle}</a> 职位 。`;
                    return `删除了项目中的 <a class="green" href="/Headhunting/MyCompany.html#/Job/${_list.extensionProperties.jobId}" target="_blank" title="${_list.extensionProperties.jobTitle}">${_list.extensionProperties.jobTitle}</a> 职位 。`;
                case 1004:
                    // return `创建了 <a class="green" href="/Headhunting/MyCompany.html#/project" target="_blank" title="${_list.extensionProperties.projectName}">${_list.extensionProperties.projectName}</a> 项目 。`;
                    return `创建了 <a class="green" href="/#/project" target="_blank" title="${_list.extensionProperties.projectName}">${_list.extensionProperties.projectName}</a> 项目 。`;
            }
        },
        handleShowMore() {
            // 每次多查5条数据 
            this.requestLatestTrends();
        },
        requestLatestTrends(isFirst) {
            this.start = isFirst ? 0: this.start;
            let params = {
                start: this.start * this.take,
                take: this.take,
                projectId: this.projectId,
            };

            this.tendLoading = true;
            projectDetailService.getProjectLatestTrends(params).then(res => {
                this.total = res.total;
                if(this.start == 0) {
                    this.list = res.list.map(item => ({
                        ...item,
                        operationTime: moment(item.updated).format("YYYY-MM-DD"),
                    }));
                } else {
                    res.list.forEach(item => {
                        this.list.push({
                            ...item,
                            operationTime: moment(item.updated).format("YYYY-MM-DD"),
                        })
                    })
                }
                this.start++;
            }).finally(() => {
                this.tendLoading = false;
            });
        },
        contactRecruiter(id) {
            // if(window.lbdTrack){
            //     lbdTrack.track('ContactRecruiterClick', {})
            // } else {
            //     _tracker.track('ContactRecruiterClick')
            // }
            setTimeout(() => {
                let shareData = Object.assign({
                        unionId: id
                    }, this.shareData);
                this.dispatch('App', 'showInstantMessage', {
                    json: shareData
                });
            }, 100);
        },
    }
};
</script>

<style lang="scss" scope>
.program-side{
    span,p,div,i,button,a,h2{
        font-family: "Microsoft YaHei";
    }
    div,button,span{
        box-sizing: border-box;
    }
    .ell{
        white-space:nowrap;
        overflow:hidden;
        text-overflow:ellipsis;
    }
    .side-top-cell{
        box-sizing: border-box;
        padding: 25px 20px 20px;
        display: flex;
        flex-flow: row nowrap;
        border-bottom: 1px solid #EEEEEE;
        .user-pic{
            width: 60px;
            height: 60px;
            border-radius: 50%;
            // border: 1px solid #E1E0DE;
            overflow: hidden;
            margin-right: 13px;
            >img{
                width: 100%;
                height: 100%;
            }
        }
        .right-cell-text{
            flex: 1;
            max-width: 207px;
            >p{
                margin: 0;
                font-size: 14px;
                font-weight: 400;
                color: #666666;
                line-height: 24px;
                .name{
                    color: black;
                    &::before{
                        content: '';
                        display: inline-block;
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        margin-right: 4px;
                        background: #B8B8B8;
                    }
                }
                .gray{
                    color: #B9B9B9;
                }
                &:last-child{
                    text-align: right;
                }
                >button{
                    width: 80px;
                    height: 24px;
                    background: #FEFEFE;
                    border: 1px solid #38BC9D;
                    border-radius: 4px;
                    font-size: 12px;
                    font-weight: 400;
                    color: #38BC9D;
                    // line-height: 17px;
                }
            }
            .line1{
                max-width: 170px;
                color: #B9B9B9;
                .name{
                    color: black;
                }
            }

        }
    }

    .program-news{
        width: 100%;
        margin-top: 32px;
        .title{
            font-size: 18px;
            font-weight: 400;
            color: #333333;
            line-height: 36px;
            &::before{
                content: '';
                display: inline-block;
                width: 8px;
                height: 20px;
                background: #38BC9D;
                margin-right: 10px;
                vertical-align: middle;
            }
        }

        .news-container{
            padding: 5px 20px 25px;
            // border: 1px solid #DDD;
            ul{
                margin-bottom: 0px;
            }
        }
        .news-item {
            width: 100%;
            height: auto;
            display: flex;
            flex-flow: row nowrap;
            box-sizing: border-box;
            margin: 12px 0;
            .pic{
                width: 40px;
                height: 40px;
                border-radius: 50%;
                overflow: hidden;
                // border: 1px solid #E1E0DE;
                margin-right: 11px;
            }
            .new-content{
                flex: 1;
                // max-width: 207px;
                max-width: 215px;
                >p{
                    font-size: 14px;
                    font-weight: 400;
                    color: #666666;
                    line-height: 17px;

                    .gray{
                        color: #B9B9B9;
                    }
                    .green{
                        color: #38BC9D;
                        white-space:normal;
                        word-break: break-all;
                        word-wrap: break-word;
                        // display: inline-block;
                        // max-width: 106px;
                        // white-space: nowrap;
                        // overflow: hidden;
                        // text-overflow: ellipsis;
                        // vertical-align: middle;
                    }
                    .name{
                        display: inline-block;
                        color: #B9B9B9;
                        max-width: 111px;
                        .real-name{
                            color: black;
                        }
                    }
                    .time{
                        color: #B9B9B9;
                        padding-left: 18px;
                        font-size: 14px;
                        // display: inline-block;
                        float: right;
                    }
                }
            }
        }
        .empty{
            text-align: center;
            padding: 30px 0 10px;
        }
        .news-bottom{
            height: 28px;
            background: #FFFFFF;
            border: 1px solid #CCCCCC;
            box-sizing: border-box;
            text-align: center;
            line-height: 26px;
            cursor: pointer;
            .news-show-more{
                color: #ccc;
                font-size: 12px;
            }
        }

    }

}


</style>