var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "program-detail" },
    [
      _c(
        "div",
        { staticClass: "top-bar-wrap" },
        [
          _c("program-detail-top", {
            attrs: { topData: _vm.baseInfo, isBindJob: _vm.isBindJob },
            on: {
              "add-job": _vm.addJob,
              "update-delivery-infomation": _vm.updateDeliveryInfomation,
              "merge-project": _vm.mergeProject,
              "edit-program": _vm.editProgram,
              "delete-program": _vm.deleteProgram,
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "main" }, [
        _c(
          "div",
          { staticClass: "content" },
          [
            _c("my-program-goal", {
              attrs: {
                isBindJob: _vm.isBindJob,
                goalData: _vm.projectDeliveryGoalItems,
              },
              on: { "eidt-goal": _vm.editGoal, "add-goal": _vm.addGoal },
            }),
            _c("program-list", {
              ref: "programList",
              attrs: { programListData: _vm.programListData },
              on: {
                "get-project-job-finish": _vm.getProjectJobFinish,
                "program-list-deal-finish": _vm.programListDealFinish,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "side-bar" },
          [
            _c("program-detail-right-side", {
              ref: "programRightSide",
              attrs: {
                projectName: _vm.baseInfo.name,
                baseData: _vm.baseInfo,
                userInfo: _vm.userInfo,
                shareData: _vm.shareData,
                isOwner: _vm.baseInfo.isOwner,
              },
            }),
          ],
          1
        ),
      ]),
      _vm.isEditProgram
        ? _c("edit-program-dialog", {
            attrs: { projectParams: _vm.projectParams },
            on: { "edit-program-finish": _vm.editProgramFinish },
          })
        : _vm._e(),
      _vm.isUpdateDeliveryInfomation
        ? _c("update-delivery-infomation", {
            attrs: { projectId: _vm.projectId, baseData: _vm.baseInfo },
            on: {
              "update-delivery-infomation-finish":
                _vm.updateDeliveryInfomationFinish,
            },
          })
        : _vm._e(),
      _vm.isAddGoal
        ? _c("add-program-goal", {
            attrs: {
              goalParams: _vm.goalParams,
              baseData: _vm.baseInfo,
              programGoalTitle: _vm.programGoalTitle,
            },
            on: { "add-goal-finish": _vm.addGoalFinish },
          })
        : _vm._e(),
      _vm.isAddJob
        ? _c("add-job-dialog", {
            on: {
              "add-job-finish": _vm.addJobFinish,
              "bind-newJob": _vm.isBindNewJob,
            },
          })
        : _vm._e(),
      _c("merge-project-dialog", {
        ref: "mergeProjectDialog",
        attrs: {
          mergeProjectArr: _vm.mergeProjectArr,
          projectId: _vm.projectId,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }