<template>
    <el-dialog
        class="update-delivery-infomation"
        title="更新交付信息"
        visible
        @close="handleClose"
        :close-on-click-modal="false"
        width="550px"
    >
        <p class="tip">请确认是否修改项目以下数据</p>
        <el-form
            :model="form"
            :rules="rules"
            ref="deliveryForm"
            label-width="110px"
        >
            <el-form-item label="HC数量" prop="headCount">
                <el-input
                    v-model="form.headCount"
                    placeholder="输入HC数量"
                    class="input-cell"
                >
                </el-input>
            </el-form-item>
            <el-form-item label="乐观程度" prop="optimisticDegree">
                <el-select
                    v-model="form.optimisticDegree"
                    placeholder="请选择乐观程度"
                >
                    <el-option label="请选择" value="0"></el-option>
                    <el-option
                        v-for="item in optimisticDegreeList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="节点重要性" prop="nodeImportance">
                <el-select
                    v-model="form.nodeImportance"
                    placeholder="请选择节点重要性"
                >
                    <el-option label="请选择" value="0"></el-option>
                    <el-option
                        v-for="item in nodeImportanceList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="接入时间" prop="accessDate" v-if="isCFUser">
                <el-date-picker
                    v-model="form.accessDate"
                    type="date"
                    placeholder="请选择接入时间">
                </el-date-picker>
            </el-form-item>
        </el-form>

        <div slot="footer" class="dialog-footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button type="primary" @click="handleConfirm">确定</el-button>
        </div>
    </el-dialog>
</template>

<script>
import projectDetailService from "#/js/service/projectDetailService.js";
import moment from "moment";

export default {
    name: "update-delivery-infomation",
    props: {
        deliveryInfomationParams: {
            type: Object,
            default: () => {},
        },
        projectId: String,
        baseData: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        var validateOptimisticDegree = (rule, value, callback) => {
            if (value === '' || value === '0') {
                callback(new Error('请选择乐观程度'));
            } else {
                callback();
            }
        };
        var validateNodeImportance = (rule, value, callback) => {
            if (value === '' || value === '0') {
                callback(new Error('请选择节点重要性'));
            } else {
                callback();
            }
        };
        return {
            optimisticDegreeList: [
                {
                    value: '1',
                    label: '乐观'
                },
                {
                    value: '2',
                    label: '不乐观'
                },
                {
                    value: '3',
                    label: '谨慎'
                },
            ],
            nodeImportanceList: [
                {
                    value: '1',
                    label: 'A类'
                },
                {
                    value: '2',
                    label: 'B类'
                },
            ],
            form: {
                headCount: "", // HC数量
                optimisticDegree: "", // 乐观程度
                nodeImportance: "", // 节点重要性
                accessDate: "", // 接入时间
            },
            rules: {
                headCount: [
                    {
                        required: true,
                        message: "请输入招聘人数",
                        trigger: "blur",
                    },
                    {
                        pattern: /^[0-9]*$/,
                        message: `请输入数字`,
                        trigger: "blur",
                    },
                    {
                        validator: (rules, value, callback) => {
                            if (value && value > 10000) {
                                callback(new Error("招聘人数不能超过10000"));
                            } else {
                                callback();
                            }
                        },
                        trigger: "blur",
                    },
                ],
                optimisticDegree: [
                    // { required: true, message: "请选择乐观程度", trigger: "change" },
                    { required: true, validator: validateOptimisticDegree, trigger: 'change' }
                ],
                nodeImportance: [
                    // { required: true, message: "请选择节点重要性", trigger: "change" },
                    { required: true, validator: validateNodeImportance, trigger: 'change' }
                ],
                accessDate: [
                    { required: true, message: "请选择接入时间", trigger: "change" },
                ],
            },
        };
    },
    computed: {
        isCFUser() {
            return this.$store.state.user.userInfo.isCFUser;
        },
    },
    created() {

    },
    mounted() {
        // console.log(this.projectId);
        // console.log(this.baseData);
        this.form.porjectId = this.projectId;
        this.form.headCount = this.baseData.headcount;
        // this.form.optimisticDegree = this.baseData.optimisticDegree == 0 ? '' : this.baseData.optimisticDegree.toString();
        // this.form.nodeImportance = this.baseData.nodeImportance == 0 ? '' : this.baseData.nodeImportance.toString();
        this.form.optimisticDegree = this.baseData.optimisticDegree.toString();
        this.form.nodeImportance = this.baseData.nodeImportance.toString();
        this.form.accessDate = this.baseData.accessDate;
    },
    methods: {
        handleClose() {
            this.$emit("update-delivery-infomation-finish", 'cancel');
        },

        // 提交
        handleConfirm() {
            this.$refs["deliveryForm"].validate((valid) => {
                if (valid) {
                    let params = {
                        ...this.form,
                        accessDate: moment(this.form.accessDate).format(
                            "YYYY-MM-DD HH:mm:ss"
                        ),
                    };
                    if(!this.isCFUser) {
                        delete params.accessDate;
                    }
                    console.log(params);
                    const loading = this.$loading({
                        lock: true,
                        text: "页面加载中~",
                        background: "rgba(255, 255, 255, 0.7)",
                    });
                    projectDetailService.updateDeliveryInfomation(params)
                        .then((res) => {
                            shortTips('更新交付信息成功');
                            this.$emit("update-delivery-infomation-finish");
                        })
                        .catch((e) => {
                            console.log(e);
                        })
                        .finally(() => {
                            loading.close();
                        });
                }
            });
        },
    },
};
</script>

<style lang="scss" scope>
.update-delivery-infomation {
    .header-title {
        font-size: 18px;
    }
    .tip {
        margin-bottom: 20px;
        font-size: 14px;
        color: #FF493C;
    }
    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 100%;
    }
}
</style>