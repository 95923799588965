<template>
    <el-dialog
        class="add-job-dialog"
        title="添加职位"
        visible
        width="820px"
        :close-on-click-modal="false"
        @close="handleClose"
    >
        <div class="job-dialog-body" v-loading="loadingList">
            <!-- 搜索职位 -->
            <div class="search-bar">
                <el-input
                    placeholder="请输入职位"
                    v-model="Keyword"
                    @keyup.enter.native="handleSearch"
                >
                    <el-button
                        slot="append"
                        icon="el-icon-search"
                        @click="handleSearch"
                    ></el-button>
                </el-input>
            </div>
            <!-- 我的职位和其他职位Tab -->
            <el-tabs v-model="activeTab" @tab-click="handleClick">
                <el-tab-pane
                    label="我的职位"
                    name="myJob"
                    :disabled="loadingList"
                ></el-tab-pane>
                <el-tab-pane
                    label="其它职位"
                    name="other"
                    :disabled="loadingList"
                ></el-tab-pane>
            </el-tabs>

            <!-- 我的职位数据 -->
            <div class="job-wrap-cell" v-show="activeTab == 'myJob'">
                <div class="jobs-wrap" ref="jobsWrap">
                    <div
                        class="job-item"
                        v-for="(jobItem, index) in my.jobsList"
                        :key="'jobs_my' + index"
                    >
                        <div class="job-l">
                            <p
                                class="item-title ell"
                                :title="
                                    jobItem.jobName +
                                    ' [' +
                                    jobItem.location +
                                    ']'
                                "
                            >
                                <span class="black">{{ jobItem.jobName }}</span>
                                [{{ jobItem.location }}]
                            </p>
                            <p>
                                <span
                                    class="activity-tag"
                                    :class="
                                        activityTag.id == 'NewJob'
                                            ? 'new-job-tag'
                                            : ''
                                    "
                                    v-for="(
                                        activityTag, tagIndex
                                    ) in jobItem.activityTags"
                                    :key="'tag_my' + tagIndex"
                                >
                                    {{ activityTag.name }}
                                </span>
                            </p>
                        </div>
                        <div class="job-m">
                            <p
                                class="ell"
                                :title="jobItem.owner"
                                v-text="jobItem.owner"
                            >
                                58同城
                            </p>
                            <p>
                                <span class="gray"
                                    >{{ jobItem.created }} 发布</span
                                >
                            </p>
                        </div>
                        <div class="job-r">
                            <el-button
                                type="primary"
                                :loading="
                                    my.isItemLoading && my.currentIndex == index
                                "
                                :disabled="
                                    my.isItemLoading && my.currentIndex != index
                                "
                                @click="handldAdd(index)"
                                >添加</el-button
                            >
                        </div>
                    </div>
                    <div class="empty-data" v-if="my.jobsList.length == 0">
                        <span class="empty-img"></span>
                        <p>暂无数据</p>
                    </div>
                </div>

                <div slot="footer" class="dialog-footer">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="my.start"
                        :page-sizes="[10, 30, 50]"
                        :page-size="my.take"
                        layout="total, prev, pager, next, jumper"
                        :total="my.total"
                    >
                    </el-pagination>
                </div>
            </div>
            <!-- 其他职位数据 -->
            <div class="job-wrap-cell" v-show="activeTab == 'other'">
                <div class="jobs-wrap" ref="otherWrap">
                    <div
                        class="job-item"
                        v-for="(jobItem, index) in other.jobsList"
                        :key="'jobs_other' + index"
                    >
                        <div class="job-l">
                            <p
                                class="item-title ell"
                                :title="
                                    jobItem.jobName +
                                    ' [' +
                                    jobItem.location +
                                    ']'
                                "
                            >
                                <span class="black">{{ jobItem.jobName }}</span>
                                [{{ jobItem.location }}]
                            </p>
                            <p>
                                <span
                                    class="activity-tag"
                                    :class="
                                        activityTag.id == 'NewJob'
                                            ? 'new-job-tag'
                                            : ''
                                    "
                                    v-for="(
                                        activityTag, tagIndex
                                    ) in jobItem.activityTags"
                                    :key="'tag_other' + tagIndex"
                                >
                                    {{ activityTag.name }}
                                </span>
                            </p>
                        </div>
                        <div class="job-m">
                            <p
                                class="ell"
                                :title="jobItem.owner"
                                v-text="jobItem.owner"
                            >
                                58同城
                            </p>
                            <p>
                                <span class="gray"
                                    >{{ jobItem.created }} 发布</span
                                >
                            </p>
                        </div>
                        <div class="job-r">
                            <el-button
                                type="primary"
                                :loading="
                                    other.isItemLoading &&
                                    other.currentIndex == index
                                "
                                :disabled="
                                    other.isItemLoading &&
                                    other.currentIndex != index
                                "
                                @click="handldAdd(index)"
                                >添加</el-button
                            >
                        </div>
                    </div>
                    <div class="empty-data" v-if="other.jobsList.length == 0">
                        <span class="empty-img"></span>
                        <p>暂无数据</p>
                    </div>
                </div>

                <div slot="footer" class="dialog-footer">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="other.start"
                        :page-sizes="[10, 30, 50]"
                        :page-size="other.take"
                        layout="total, prev, pager, next, jumper"
                        :total="other.total"
                    >
                    </el-pagination>
                </div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import moment from "moment";
import projectDetailService from "#/js/service/projectDetailService.js";

export default {
    name: "add-job-dialog",
    components: {},
    data() {
        return {
            Keyword: "",
            activeTab: "myJob",
            loadingList: false,
            isEdit: false, // 是否添加职位标识
            isOtherFirst: true,

            my: {
                start: 1,
                take: 10,
                total: 0,
                jobsList: [],
                currentIndex: -1,
                isItemLoading: false,
            },
            other: {
                start: 1,
                take: 10,
                total: 0,
                jobsList: [],
                currentIndex: -1,
                isItemLoading: false,
            },
        };
    },
    computed: {
        projectId() {
            return this.$route.params.projectId;
        },
    },
    created() {
        this.getJobPageData();
    },
    mounted() {},
    methods: {
        getJobPageData() {
            const start =
                this.activeTab == "myJob" ? this.my.start : this.other.start;
            const take =
                this.activeTab == "myJob" ? this.my.take : this.other.take;
            let params = {
                start: (start - 1) * take,
                take: take,
                projectId: this.projectId,
                Keyword: this.Keyword,
                // isMyJob: this.activeTab == "myJob",
                Filter: this.activeTab == "myJob" ? 1 : 2,
            };

            this.loadingList = true;
            projectDetailService
                .BatchGetProjectNoBindingList(params)
                .then((res) => {
                    const _jobsList = res.list.map((item) => ({
                        ...item,
                        created: moment(item.created).format("YYYY-MM-DD"),
                    }));
                    if (this.activeTab == "myJob") {
                        this.my.total = res.total;
                        this.my.jobsList = _jobsList;
                        this.$refs.jobsWrap.scrollTop = 0;
                    } else {
                        this.other.total = res.total;
                        this.other.jobsList = _jobsList;
                        this.isOtherFirst = false;
                        this.$refs.otherWrap.scrollTop = 0;
                    }
                })
                .finally(() => {
                    this.loadingList = false;
                })
                .catch((err) => {
                    // console.log(err);
                });
        },
        handleClose() {
            this.$emit("add-job-finish", this.isEdit);
        },
        handleClick(ve) {
            // this.Keyword = '';
            this.activeTab = ve.name;
            if (this.activeTab == "other" && this.isOtherFirst) {
                this.getJobPageData();
            }
        },
        // 添加职位
        handldAdd(index) {
            const key = this.activeTab == "myJob" ? "my" : "other";
            const params = {
                projectId: this.projectId,
                jobIds: [this[key].jobsList[index].jobId],
            };

            this[key].currentIndex = index;
            this[key].isItemLoading = true;
            projectDetailService
                .AddProjectJobBindings(params)
                .then((res) => {
                    shortTips("职位添加成功！");
                    this.getJobPageData();
                    this.isEdit = true;
                    // 绑定新的职位成功，传给父组件
                    this.$emit("bind-newJob");
                })
                .finally(() => {
                    this[key].isItemLoading = false;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        handleSearch() {
            const key = this.activeTab == "myJob" ? "my" : "other";
            this[key].start = 1;
            this.getJobPageData();
        },

        handleSizeChange(val) {
            const key = this.activeTab == "myJob" ? "my" : "other";
            this[key].start = 1;
            this[key].take = val;
            this.getJobPageData();
        },
        handleCurrentChange(val) {
            const key = this.activeTab == "myJob" ? "my" : "other";
            this[key].start = val;
            this.getJobPageData();
        },
    },
};
</script>

<style lang="scss" scope>
.ell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.add-job-dialog {
    // .el-dialog__body{
    //     padding-left: 0;
    //     padding-right: 0;
    // }

    div {
        box-sizing: border-box;
    }
    .job-dialog-body {
        .search-bar {
            width: 450px;
            margin: 5px auto 20px;
            .el-input__inner {
                border: 1px solid #38bc9d;
            }
            .el-input-group__append {
                border: 1px solid #38bc9d;
                background: #38bc9d;
                .el-button--default {
                    &:hover,
                    &:active,
                    &:focus {
                        background: transparent;
                        border: 0;
                    }
                    .el-icon-search {
                        color: white;
                    }
                }
            }
        }
        .jobs-wrap {
            height: auto;
            max-height: 320px;
            overflow-y: auto;
            .job-item {
                width: 100%;
                height: auto;
                border-bottom: 1px solid #ececec;
                padding: 20px 12px;
                display: flex;
                p {
                    margin: 0;
                    font-size: 14px;
                    font-weight: 400;
                    color: #666666;
                    > span {
                        .gray {
                            color: #b9b9b9;
                        }
                        .black {
                            color: #333333;
                        }
                        &.job-danger {
                            display: inline-block;
                            font-size: 12px;
                            color: #ff493c;
                            height: 20px;
                            line-height: 17px;
                            padding: 0 7px;
                            box-sizing: border-box;
                            border: 1px solid #ff493c;
                            border-radius: 10px;
                        }
                    }
                }

                .activity-tag {
                    height: 16px;
                    margin-left: 6px;
                    padding: 0 3px;
                    border-radius: 4px;
                    background: $primary;
                    font-size: 12px;
                    line-height: 16px;
                    color: #fff;
                    background: #39f;
                }
                .new-job-tag {
                    background: #ff493c;
                }
                .item-title {
                    font-size: 16px;
                    margin-bottom: 5px;
                    color: #b9b9b9;
                    .gray {
                        color: #b9b9b9;
                    }
                    .black {
                        color: #000;
                    }
                }

                .job-l {
                    flex: 1;
                    max-width: 432px;
                }
                .job-m {
                    width: 190px;
                    box-sizing: border-box;
                    padding-right: 5px;
                }
                .job-r {
                    width: 118px;
                    .el-button--primary {
                        width: 117px;
                        height: 34px;
                    }
                }
            }

            .empty-data {
                text-align: center;
                .empty-img {
                    display: inline-block;
                    background: url("~@src/assets/images/home/icon_no_data.svg")
                        no-repeat;
                    background-size: contain;
                    width: 160px;
                    height: 160px;
                }
                > p {
                    font-size: 16px;
                    line-height: 21px;
                    margin-bottom: 0px;
                }
            }
        }

        .dialog-footer {
            text-align: center;
        }
    }
}
</style>