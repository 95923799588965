<template>
    <div class="top-bar-project">
        <div class="top-bar-left">
            <div class="top-l">
                <div class="logo-wrap">
                    <img
                        :src="
                            baseData.firmLogoUrl ||
                            '//hstatic.hirede.com/lbd/images/firm-default-avatar.jpg'
                        "
                    />
                </div>
                <p class="company-name ell" :title="baseData.customerName">
                    {{ baseData.customerName ? baseData.customerName : "" }}
                </p>
            </div>
            <div class="top-m">
                <!-- 项目名称 -->
                <div class="program-name ell" :title="baseData.name ? baseData.name.replaceAll('$#$', '-') : ''">
                    {{ baseData.name ? baseData.name.replaceAll("$#$", "-") : "" }}
                </div>
                <p>
                    <span class="company ell" :title="baseData.customerName">{{
                        baseData.customerName ? baseData.customerName : "未知"
                    }}</span>
                    <span
                        class="local ell"
                        :title="computedCitys(baseData.locationIds)"
                        v-text="computedCitys(baseData.locationIds)"
                        >深圳市、西安市、成都市、上海市</span
                    >
                    <!-- <span
                        class="local ell"
                        :title="computedCitys(baseData.locationIds)"
                        >{{ computedCitys(baseData.locationIds) }}</span
                    > -->
                </p>
                <p>
                    <span class="depart">{{
                        baseData.customerDeparmentName
                            ? baseData.customerDeparmentName
                            : "未知"
                    }}</span>
                    <!-- <span class="duty" v-text="baseData.jobSerieName">应用产品开发工程技术</span> -->
                    <!-- <span class="hunt-num"
                        >需
                        {{ baseData.headcount ? baseData.headcount : 0 }} 人</span
                    > -->
                </p>
                <p class="delivery-info">
                    <span>HC数量：<i>{{ baseData.headcount ? baseData.headcount : 0 }}</i></span>
                    <span>
                        乐观程度：
                        <i v-if="baseData.optimisticDegree == 0">-</i>
                        <i v-else>{{ (baseData.optimisticDegree == '1' || baseData.optimisticDegree == '2') ? (baseData.optimisticDegree == '1' ? '乐观' : '不乐观') : '谨慎' }}</i>
                    </span>
                    <span>
                        节点重要性：
                        <i v-if="baseData.nodeImportance == 0">-</i>
                        <i v-else>{{ baseData.nodeImportance == '1' ? 'A类' : 'B类' }}</i>
                    </span>
                    <span v-if="isCFUser && baseData.accessDate">我接入的时间：<i>{{ baseData.accessDate | accessDateFilter }}</i></span> 
                    <span v-if="isCFUser && !baseData.accessDate">我接入的时间：<i>-</i></span> 

                    
                    <!-- <template v-if="canSeeHrInfos && baseData.hrName">
                        <br>
                        <span>
                            HR 姓名：
                            <i>{{ baseData.hrName || '-' }}</i>
                        </span>
                        <span>
                            HR 手机：
                            <i>{{ baseData.hrPhone || '-' }}</i>
                        </span>
                        <span>
                            HR 微信：
                            <i>{{ baseData.hrWeChat || '-' }}</i>
                        </span>
                        <span>
                            HR 职位：
                            <i>{{ baseData.hrTitle || '-' }}</i>
                        </span>
                        <span>
                            HR 邮箱：
                            <i>{{ baseData.hrEmail || '-' }}</i>
                        </span>
                        <el-tooltip effect="light" content="HR 相关信息为私密信息，仅自己可见" placement="right">
                            <el-button type="text secret">
                                <i class="el-icon-view secret"></i>私密
                            </el-button>
                        </el-tooltip>
                    </template> -->
                </p>
                <div class="hr-card" v-if="canSeeHrInfos && baseData.hrName">
                    <!-- <div class="tit">
                        <span>HR信息</span>
                        <el-tooltip effect="light" :value="true" content="HR 相关信息为私密信息，仅自己可见" placement="right">
                            <el-button type="text secret">
                                <i class="el-icon-unlock secret"></i>私密
                            </el-button>
                        </el-tooltip>
                    </div> -->
                    <p class="p1">{{baseData.hrName || '-'}} {{baseData.hrPhone}} 
                        <el-tooltip effect="light" content="HR 相关信息为私密信息，仅自己可见" placement="right">
                            <el-button type="text secret">
                                <i class="el-icon-unlock secret"></i>私密
                            </el-button>
                        </el-tooltip>
                    </p>
                    <p class="p2">
                        <span class="cel">HR{{getContactTypeText(baseData.hrWeChat)}}：{{ getWeChat(baseData.hrWeChat) }}</span>
                        <span class="cel">HR职位：{{ baseData.hrTitle || '-' }}</span>
                        <span class="cel">HR邮箱：{{ baseData.hrEmail || '-' }}</span>
                    </p>
                </div>
                <div class="total-data">
                    <span
                        >推荐<i>{{
                            baseData.recommendCount ? baseData.recommendCount : 0
                        }}</i></span
                    >
                    <span
                        >面试<i>{{
                            baseData.interviewCount ? baseData.interviewCount : 0
                        }}</i></span
                    >
                    <span
                        >offer<i>{{
                            baseData.offerCount ? baseData.offerCount : 0
                        }}</i></span
                    >
                </div>
            </div>
        </div>

        <div class="top-bar-right">
            <div class="top-r">
                <div class="btn-wrap">
                    <button @click="addJob">添加职位</button>
                    <!-- <button @click="addGoal">添加项目交付目标</button> -->
                    <button @click="updateDeliveryInfomation" v-if="isBindJob">更新交付信息</button>
                </div>
                <div
                    class="btn-wrap"
                    v-if="baseData.isOwner || userInfo.isAdministrator"
                >
                    <a href="javascript:;" @click="editProgram"
                        ><img
                            src="~@src/assets/images/program/edit_icon.png"
                            alt=""
                        />编辑</a
                    >
                    <a href="javascript:;" @click="deleteProgram"
                        ><img
                            src="~@src/assets/images/program/delete_icon.png"
                            alt=""
                        />删除</a
                    >
                    <a href="javascript:;" @click="mergeProject">
                        <i class="el-icon-copy-document"></i>
                        合并到另一项目
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import cityMap from "#/js/config/provinceCity.json";
import {contactType} from "@src/js/config/dates/projectData.js";
export default {
    name: "program-detail-top",
    props: {
        isBindJob: Boolean,
        topData: {
            type: Object,
            default: () => {},
        },
    },
    components: {},
    data() {
        return {};
    },
    computed: {
        baseData() {
            return this.topData || {};
        },
        userInfo() {
            return this.$store.state.user.userInfo;
        },
        isCFUser() {
            return this.$store.state.user.userInfo.isCFUser;
        },
        canSeeHrInfos() {
            return this.userInfo.isAdministrator || 
                this.userInfo.roleCodeList.includes('InternalControl') || 
                this.baseData.isOwner;
        },
    },
    filters: {
        accessDateFilter(str) {
            // console.log(str)
            let result = moment(str).format('YYYY-MM-DD');
            return result;
        }
    },
    mounted() {},
    methods: {
        getContactTypeText(hrWeChat) {
            if(!hrWeChat) return '微信';
            if(hrWeChat.indexOf('|') > -1) {
                let arr = hrWeChat.split("|");
                return contactType.find(it => it.value == arr[0])?.label || '微信';
            } else {
                return '微信';
            }
        },
        getWeChat(hrWeChat) {
            if(!hrWeChat) return '-';
            if(hrWeChat.indexOf('|') > -1) { 
                return hrWeChat.split("|")[1];
            }
            return hrWeChat;
        },
        addJob() {
            this.$emit("add-job");
        },
        // addGoal() {
        //     this.$emit("add-goal");
        // },
        updateDeliveryInfomation() {
            this.$emit("update-delivery-infomation");
        },
        // 编辑项目
        editProgram() {
            this.$emit("edit-program");
        },
        // 删除项目
        deleteProgram() {
            this.$emit("delete-program");
        },
        mergeProject() {
            this.$emit("merge-project");
        },

        // 将ids转换成城市名
        computedCitys(ids) {
            if (!ids) return;
            const idsArray = ids.split(".");
            const currentCitys = idsArray.map((item) => {
                return cityMap[item];
            });
            return currentCitys.join("、");
        },

        goToUrl(url, isBlank) {
            if (isBlank) {
                window.open(url);
            } else {
                location.href = url;
            }
        },
    },
};
</script>

<style lang="scss" scope>
.ell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.top-bar-project {
    span,
    p,
    div,
    i,
    button,
    a {
        font-family: "Microsoft YaHei";
    }
    div,
    button {
        box-sizing: border-box;
    }
    display: flex;
    // max-height: 258px;
    box-sizing: border-box;
    padding: 45px 60px 32px;
    > div {
        vertical-align: top;
        font-size: 0;
    }
    .top-bar-left {
        width: 70%;
        display: flex;
        .top-l {
            display: inline-block;
            width: 195px;
            margin-right: 31px;
            .logo-wrap {
                width: 195px;
                height: 94px;
                background: #ffffff;
                border: 1px solid #ececec;
                box-sizing: border-box;
                padding: 8px 21px;
                > img {
                    width: 100%;
                    height: 100%;
                }
            }
            .company-name {
                font-size: 16px;
                font-family: "Microsoft YaHei";
                font-weight: 400;
                color: #333333;
                line-height: 36px;
                margin: 0;
                text-align: center;
            }
        }
        .top-m {
            display: inline-block;
            // width: 616px;
            flex: 1;
            width: 70%;
            .program-name {
                font-size: 22px;
                font-weight: 400;
                color: #333333;
                line-height: 36px;
                margin-bottom: 20px;
            }
            > p {
                margin: 0 0 6px;
                // height: 25px;
                > span {
                    font-size: 16px;
                    font-weight: 400;
                    color: #666666;
                    line-height: 25px;
                    margin-right: 20px;
                    &::before {
                        vertical-align: middle;
                        margin-right: 10px;
                    }
                    &:first-child {
                        margin-right: 20px;
                    }
                }
                .company {
                    display: inline-block;
                    max-width: 48%;
                    vertical-align: top;
                    &::before {
                        content: "";
                        display: inline-block;
                        background: url("~@src/assets/images/program/customer_icon.png")
                            no-repeat;
                        background-size: 15px 13px;
                        width: 15px;
                        height: 13px;
                    }
                }
                .local {
                    display: inline-block;
                    max-width: 45%;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    display: inline-block;
                    &::before {
                        content: "";
                        display: inline-block;
                        background: url("~@src/assets/images/program/local_icon.png")
                            no-repeat;
                        background-size: 15px 13px;
                        width: 15px;
                        height: 13px;
                    }
                }
                .depart {
                    &::before {
                        content: "";
                        display: inline-block;
                        background: url("~@src/assets/images/program/depart_icon.png")
                            no-repeat;
                        background-size: 13px 13px;
                        width: 13px;
                        height: 13px;
                    }
                }
                .duty {
                    &::before {
                        content: "";
                        display: inline-block;
                        background: url("~@src/assets/images/program/duty_icon.png")
                            no-repeat;
                        background-size: 7px 14px;
                        width: 7px;
                        height: 14px;
                    }
                }
                .hunt-num {
                    &::before {
                        content: "";
                        display: inline-block;
                        background: url("~@src/assets/images/program/hunt_num_icon.png")
                            no-repeat;
                        background-size: 13px 13px;
                        width: 13px;
                        height: 13px;
                    }
                }
            }
            .delivery-info {
                span {
                    color: #999999;
                    font-size: 14px;
                    i {
                        color: #666666;
                    }
                }
                .secret{
                    color: #F56C6C;
                    >span{
                        color: #F56C6C;
                    }
                }
            }
            .hr-card{
                background: #FAFAFA;
                border-radius: 5px;
                padding: 10px;
                margin-bottom: 6px;
                .tit{
                    >span{
                        &::before{
                            display: inline-block;
                            content: '';
                            width: 3px;
                            height: 14px;
                            background: $primary;
                            margin-right: 8px;
                        }
                        font-weight: 600;
                        font-size: 16px;
                        color: #333333;
                        line-height: 22px;
                    }

                    .secret{
                        color: #F56C6C;
                        >span{
                            color: #F56C6C;
                        }
                    }

                }
                .p1{
                    line-height: 22px;
                    // margin: 6px 0 0;
                    margin: 0;
                    font-weight: 500;
                    font-size: 16px;
                    color: #333333;
                    line-height: 22px;
                    text-align: left;
                    .secret{
                        color: #F56C6C;
                        >span{
                            color: #F56C6C;
                        }
                    }
                }
                .p2{
                    line-height: 20px;
                    margin: 6px 0 0;
                    font-size: 14px;
                    color: #999999;
                    line-height: 20px;
                    .cel{
                        &::after{
                            display: inline-block;
                            content: '';
                            width: 1px;
                            height: 15px;
                            background: #979797;
                            margin: 0 10px;
                            vertical-align: middle;
                        }
                        &:last-child{
                            &::after{
                                display: none;
                            }
                        }
                    }

                }
            }
            .total-data {
                margin-top: 4px;
                > span {
                    font-size: 14px;
                    font-weight: 400;
                    color: #38bc9c;
                    line-height: 16px;
                    margin-right: 15px;
                    > i {
                        color: #ff7200;
                        margin-left: 7px;
                    }
                }
            }
        }
    }
    .top-bar-right {
        width: 30%;
        .top-r {
            display: inline-block;
            width: 320px;
            margin-left: 38px;
            .btn-wrap {
                margin-bottom: 20px;
                > button {
                    width: 150px;
                    height: 46px;
                    background: #38bc9d;
                    border-radius: 2px;
                    font-size: 16px;
                    font-weight: 400;
                    color: #ffffff;
                    // line-height: 36px;
                    &:first-child {
                        margin-right: 20px;
                    }
                }
                > a {
                    font-size: 14px;
                    font-weight: 400;
                    color: #38bc9d;
                    margin-right: 30px;
                    &:first-child {
                        margin-left: 5px;
                    }
                    > img {
                        width: 16px;
                        height: 16px;
                        margin-right: 6px;
                    }
                }
            }
        }
    }
}
</style>